import { ORDER_STATUS, ORDER_TYPE, ROLE } from '../constant.js';
import firebase_init, { auth, Authprovider, firestore, storageRef, database } from './firebase.js';
import { groupBy } from 'lodash';
import { Area } from '../bo-page/NewItemForm.js';
const userId = localStorage.getItem('userId');

export class BoService {
    static getProductList(callback, shopId?) {
        console.log(userId);
        const id = shopId || userId;
        database.ref(`BOShopList/${id}`).once('value', (snapshot) => {
            let data = [];
            snapshot.forEach((childSnapshot) => {
                const childKey = childSnapshot.key;
                const childData = childSnapshot.val();
                data.push({
                    id: childKey,
                    ...childData,
                });
            });
            if (callback) {
                callback(data);
            }
        });
    }

    static createProduct(data, callback) {
        console.log(userId);
        const ref = database.ref(`BOShopList/${userId}`);
        const itemRef = ref.push();
        itemRef.set(
            {
                ...data,
                owner: userId,
                isPublished: userId == 'admin',
            },
            (error) => {
                if (!error) {
                    callback();
                }
            }
        );
    }

    static updateProduct(data, callback) {
        const ref = database.ref(`BOShopList/${userId}/${data.id}`);
        ref.set(data, (error) => {
            if (!error) {
                if (callback) {
                    callback();
                }
            }
        });
    }

    static togglePublishProduct(data, isPublished, owner, callback) {
        const ref = database.ref(`BOShopList/${owner}/${data.id}`);
        ref.set({ ...data, isPublished }, (error) => {
            if (!error) {
                callback();
            }
        });
    }

    static removeProduct(data, callback) {
        const ref = database.ref(`BOShopList/${userId}/${data.id}`);
        ref.remove().then(() => {
            callback();
        });
    }

    static uploadItemImage(file, callback) {
        let imageRef = storageRef.child(`item-images/${userId}/${file.name}`);
        imageRef.put(file).then(function (snapshot) {
            const ref = storageRef.child(`item-images/${userId}/${file.name}`);
            ref.getDownloadURL().then((url) => {
                if (callback) {
                    callback(url);
                }
            });
        });
    }

    static removeItemImage(fileName) {
        let imageRef = storageRef.child(`item-images/${userId}/${fileName}`);
        imageRef.delete().then(function (snapshot) {});
    }

    static uploadItemVideo(file, callback) {
        let videoRef = storageRef.child(`item-videos/${userId}/${file.name}`);
        videoRef.put(file).then(function (snapshot) {
            const ref = storageRef.child(`item-videos/${userId}/${file.name}`);
            ref.getDownloadURL().then((url) => {
                if (callback) {
                    callback(url);
                }
            });
        });
    }

    static removeItemVideo(fileName) {
        let imageRef = storageRef.child(`item-videos/${userId}/${fileName}`);
        imageRef.delete().then(function (snapshot) {});
    }

    static createShopAccount(data, callback) {
        firestore
            .collection('shopList')
            .add(data)
            .then((docRef) => {
                callback();
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static updateShop(data, callback) {
        firestore
            .collection('shopList')
            .doc(data.id)
            .update(data)
            .then((docRef) => {
                callback();
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static removeShop(data, callback) {
        firestore
            .collection('shopList')
            .doc(data.id)
            .delete()
            .then(() => {
                callback();
            })
            .catch((error) => {
                console.error('Error removing document: ', error);
            });

        const ref = database.ref(`BOShopList/${data.id}`);
        ref.remove().then(() => {
            callback();
        });
    }

    static login(username, password, callback) {
        console.log(username, password);
        firestore
            .collection('accounts')
            .where('username', '==', username)
            .where('password', '==', password)
            .get()
            .then((querySnapshot) => {
                if (querySnapshot.docs.length > 0) {
                    const doc = querySnapshot.docs[0];
                    callback({
                        id: doc.id,
                        ...doc.data(),
                    });
                    return;
                }
                callback(null);
            })
            .catch((error) => {
                callback(null);
            });
    }

    static createCate(data, callback) {
        console.log(userId);
        const ref = database.ref(`Categories/${userId}`);
        const itemRef = ref.push();
        itemRef.set(
            {
                ...data,
                owner: userId,
            },
            (error) => {
                if (!error) {
                    callback();
                }
            }
        );
    }

    static updateCate(data, callback) {
        const ref = database.ref(`Categories/${userId}/${data.id}`);
        ref.set(data, (error) => {
            if (!error) {
                callback();
            }
        });
    }

    static removeCate(data, callback) {
        const ref = database.ref(`Categories/${userId}/${data.id}`);
        ref.remove().then(() => {
            callback();
        });
    }

    static getCategories(callback, ownerId) {
        console.log(userId);
        database.ref(`Categories/${ownerId || userId}`).once('value', (snapshot) => {
            let data = [];
            snapshot.forEach((childSnapshot) => {
                const childKey = childSnapshot.key;
                const childData = childSnapshot.val();
                data.push({
                    id: childKey,
                    ...childData,
                });
            });
            if (callback) {
                callback(data);
            }
        });
    }

    static getCategoriesOfAdmin(callback) {
        console.log(userId);
        const id = 'admin';
        database.ref(`Categories/${id}`).once('value', (snapshot) => {
            let data = [];
            snapshot.forEach((childSnapshot) => {
                const childKey = childSnapshot.key;
                const childData = childSnapshot.val();
                data.push({
                    id: childKey,
                    ...childData,
                });
            });
            if (callback) {
                callback(data);
            }
        });
    }

    static getPostList(callback) {
        firestore
            .collection('products')
            .get()
            .then((querySnapshot) => {
                let data = [];
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });
                callback(data);
            });
    }

    static getDisablePostOfManager(userId, callback) {
        firestore
            .collection('customer-disable')
            .doc(userId)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    callback({ ...doc.data(), id: doc.id });
                    return;
                }
                callback(null);
            });
    }

    static disbleManagerProduct(userId, currentDisables, data, enabled, callback) {
        const newArray = enabled ? currentDisables.filter((proId) => proId !== data.id) : [...currentDisables, data.id];
        firestore
            .collection('customer-disable')
            .doc(userId)
            .set({ list: newArray })
            .then((docRef) => {
                if (callback) callback();
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static getPostById(id, callback) {
        firestore
            .collection('products')
            .doc(id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    callback({ ...doc.data(), id: doc.id });
                    return;
                }
                callback(null);
            });
    }

    static getPostVariantsById(id, callback) {
        firestore
            .collection('products')
            .where('originId', '==', id)
            .get()
            .then((querySnapshot) => {
                let data = [];
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });
                callback(data);
            });
    }

    static createPost(data, callback) {
        firestore
            .collection('products')
            .add({ ...data, owner: userId })
            .then((docRef) => {
                if (callback) {
                    callback({ id: docRef.id, ...data });
                }
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static createPostVariant(data, variantData, callback) {
        firestore
            .collection('products')
            .add({ ...variantData, originId: data.id, owner: userId, unit: data.unit, videos: data.videos, desc: data.desc })
            .then((docRef) => {
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static togglePost(data, enabled, callback) {
        firestore
            .collection('products')
            .doc(data.id)
            .update({ ...data, enabled })
            .then((docRef) => {
                if (callback) callback();
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static updatePost(data, callback) {
        firestore
            .collection('products')
            .doc(data.id)
            .update(data)
            .then((docRef) => {
                if (callback) {
                    callback({ id: data.id, ...data });
                }
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static removePost(data, callback) {
        firestore
            .collection('products')
            .doc(data.id)
            .delete()
            .then(() => {
                if (callback) callback();
            })
            .catch((error) => {
                console.error('Error removing document: ', error);
            });
    }

    static updatePostVariant(data, variantData, callback) {
        firestore
            .collection('products')
            .doc(variantData.id)
            .update({ ...variantData, unit: data.unit, videos: data.videos, desc: data.desc })
            .then((docRef) => {
                if (callback) callback();
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static removePostVariant(data, callback) {
        firestore
            .collection('products')
            .doc(data.id)
            .delete()
            .then(() => {
                if (callback) callback();
            })
            .catch((error) => {
                console.error('Error removing document: ', error);
            });
    }

    static createOrder(data, callback) {
        firestore
            .collection('orders')
            .add({ ...data, createdDate: new Date() })
            .then((docRef) => {
                callback();
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static getOrders(callback) {
        firestore
            .collection('orders')
            .get()
            .then((querySnapshot) => {
                let data = [];
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });
                callback(data);
            });
    }

    static updateOrderStatus(order, status, callback) {
        const newData = { ...order, status };
        firestore
            .collection('orders')
            .doc(order.id)
            .update(newData)
            .then((docRef) => {
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static updateOrderPaid(order, isPaid, callback) {
        const newData = { ...order, isPaid };
        firestore
            .collection('orders')
            .doc(order.id)
            .update(newData)
            .then((docRef) => {
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static getExchangeData(callback) {
        database.ref(`Currencies`).once('value', (snapshot) => {
            let data = snapshot.val() || {};
            if (callback) {
                callback(data);
            }
        });
    }

    static updateExchangeData(data, callback) {
        const ref = database.ref('Currencies');
        ref.set(data, (error) => {
            if (!error) {
                callback();
            }
        });
    }
    ///////////////// SHOP /////////////////

    static getShopList = (callback) => {
        firestore
            .collection('accounts')
            .where('role', '==', ROLE.SHOP)
            .get()
            .then((querySnapshot) => {
                let data = [];
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });

                callback(data);
            });
    };

    static createShop(data, callback) {
        const shop = { ...data, info: { name: data.name, address: data.address } };
        firestore
            .collection('accounts')
            .add({ ...shop })
            .then((docRef) => {
                const newData = { ...shop, id: docRef.id, info: { ...shop.info, managerId: docRef.id } };
                console.log(newData, shop);
                BoService.updateShopAccount(newData, callback);
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static updateShopAccount(data, callback) {
        firestore
            .collection('accounts')
            .doc(data.id)
            .update(data)
            .then((docRef) => {
                if (callback) callback();
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static getShopAccount(id, callback) {
        firestore
            .collection('accounts')
            .doc(id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    callback({ ...doc.data(), id: doc.id });
                    return;
                }
                callback(null);
            });
    }

    static getAllAccount(callback) {
        firestore
            .collection('accounts')
            .get()
            .then((querySnapshot) => {
                const data = [];
                querySnapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    data.push({ ...doc.data(), id: doc.id });
                });
                callback(data);
            });
    }

    static getShopInfo(id, callback) {
        firestore
            .collection('accounts')
            .doc(id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    const data = doc.data();
                    callback({ ...data.info, id: doc.id });
                    return;
                }
                callback(null);
            });
    }

    static getShopProduct(callback) {
        firestore
            .collection('shop-products')
            .get()
            .then((querySnapshot) => {
                let data = [];
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });
                callback(data);
            });
    }

    static getShopProductById(id, callback) {
        firestore
            .collection('shop-products')
            .doc(id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    callback({ ...doc.data(), id: doc.id });
                    return;
                }
                callback(null);
            });
    }

    static getShopProductVariantsById(id, callback) {
        firestore
            .collection('shop-products')
            .where('originId', '==', id)
            .get()
            .then((querySnapshot) => {
                let data = [];
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });
                callback(data);
            });
    }

    static createShopProduct(data, callback) {
        firestore
            .collection('shop-products')
            .add({ ...data, owner: userId })
            .then((docRef) => {
                if (callback) {
                    callback({ id: docRef.id, ...data });
                }
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static createShopProductVariant(data, variantData, callback) {
        firestore
            .collection('shop-products')
            .add({ ...variantData, originId: data.id, owner: userId, unit: data.unit, videos: data.videos, desc: data.desc })
            .then((docRef) => {
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static toggleShopProduct(data, enabled, callback) {
        firestore
            .collection('shop-products')
            .doc(data.id)
            .update({ ...data, enabled })
            .then((docRef) => {
                if (callback) callback();
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static updateShopProduct(data, callback) {
        console.log('update', data);
        firestore
            .collection('shop-products')
            .doc(data.id)
            .update(data)
            .then((docRef) => {
                if (callback) {
                    callback({ id: data.id, ...data });
                }
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static updateShopComments(data, comments, callback) {
        firestore
            .collection('shop-products')
            .doc(data.id)
            .update({ comments })
            .then((docRef) => {
                if (callback) callback();
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static removeShopProduct(data, callback) {
        firestore
            .collection('shop-products')
            .doc(data.id)
            .delete()
            .then(() => {
                if (callback) callback();
            })
            .catch((error) => {
                console.error('Error removing document: ', error);
            });
    }

    static updateShopProductVariant(data, variantData, callback) {
        firestore
            .collection('shop-products')
            .doc(variantData.id)
            .update({ ...variantData, unit: data.unit, videos: data.videos, desc: data.desc })
            .then((docRef) => {
                if (callback) callback();
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static removeShopProductVariant(data, callback) {
        firestore
            .collection('shop-products')
            .doc(data.id)
            .delete()
            .then(() => {
                if (callback) callback();
            })
            .catch((error) => {
                console.error('Error removing document: ', error);
            });
    }

    static createShopOrder(data, callback) {
        firestore
            .collection('shop-orders')
            .add({ ...data, createdDate: new Date() })
            .then((docRef) => {
                callback();
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static getShopOrders(callback) {
        firestore
            .collection('shop-orders')
            .get()
            .then((querySnapshot) => {
                let data = [];
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });
                callback(data);
            });
    }

    static updateShopOrderStatus(order, status, callback) {
        const newData = { ...order, status };
        firestore
            .collection('shop-orders')
            .doc(order.id)
            .update(newData)
            .then((docRef) => {
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static updateShopOrderPaid(order, isPaid, callback) {
        const newData = { ...order, isPaid };
        firestore
            .collection('shop-orders')
            .doc(order.id)
            .update(newData)
            .then((docRef) => {
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    /////////////////////////////////
    static logout() {
        localStorage.removeItem('username');
        localStorage.removeItem('userId');
        localStorage.removeItem('userType');
    }

    static setUserData(username, userId, userData) {
        localStorage.setItem('username', username);
        localStorage.setItem('userId', userId);
        localStorage.setItem('userType', 'ShopUser');
        localStorage.setItem('role', userData.role);
        localStorage.setItem('apartmentId', userData.apartmentId);
        localStorage.setItem('userData', JSON.stringify(userData));
    }

    static getShipService(callback) {
        firestore
            .collection('shipService')
            .get()
            .then((querySnapshot) => {
                let data = [];
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });
                callback(data);
            });
    }

    static getOrderList(callback) {
        const userType = localStorage.getItem('userType');
        const userId = localStorage.getItem('userId');
        let query = firestore.collection('orderList');
        if (userType == 'ShopUser' && userId !== 'admin') {
            query = query.where('shopId', '==', userId);
        }
        query
            .get()
            .then((querySnapshot) => {
                let data = [];
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });
                callback(data);
            })
            .catch((error) => {
                callback(null);
            });
    }

    static getNormalUserList(callback) {
        firestore
            .collection('normalUsers')
            .get()
            .then((querySnapshot) => {
                let data = [];
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });
                callback(data);
            })
            .catch((error) => {
                callback(null);
            });
    }

    static depositUsersAccount(userData, amount) {
        const data = { ...userData };
        data.balance = parseInt(data.balance || 0) + parseInt(amount);
        firestore
            .collection('normalUsers')
            .doc(data.id)
            .update(data)
            .then((docRef) => {})
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static depositShopAccount(shop, amount) {
        const data = { ...shop };
        data.balance = parseInt(data.balance || 0) + parseInt(amount);
        firestore
            .collection('shopList')
            .doc(data.id)
            .update(data)
            .then((docRef) => {})
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static getInvestProductList(callback, shopId?) {
        database.ref(`InvestProduct`).once('value', (snapshot) => {
            let data = [];
            snapshot.forEach((childSnapshot) => {
                const childKey = childSnapshot.key;
                const childData = childSnapshot.val();
                data.push({
                    id: childKey,
                    ...childData,
                });
            });
            if (callback) {
                callback(data);
            }
        });
    }

    static createInvestProduct(data, callback) {
        const ref = database.ref(`InvestProduct`);
        const itemRef = ref.push();
        itemRef.set(
            {
                ...data,
                isInvestProduct: true,
            },
            (error) => {
                if (!error) {
                    callback();
                }
            }
        );
    }

    static updateInvestProduct(data, callback) {
        const ref = database.ref(`InvestProduct/${data.id}`);
        ref.set({ ...data, isInvestProduct: true }, (error) => {
            if (!error) {
                if (callback) {
                    callback();
                }
            }
        });
    }
    static removeInvestProduct(data, callback) {
        const ref = database.ref(`InvestProduct/${data.id}`);
        ref.remove().then(() => {
            callback();
        });
    }

    static addInvestProductToShop(item, amount) {
        const userId = localStorage.getItem('userId');
        const ref = database.ref(`InvestProductOfShop/${userId}/${item.id}`);
        let data = null;
        ref.once('value', (snapshot) => {
            data = snapshot.val();
            console.log('Read data', data);
            if (!data) {
                data = { ...item };
                data.ownerId = userId;
                data.stock = parseInt(amount) || 1;
                (data.productOptions || []).forEach((op) => {
                    op.stock = parseInt(amount) || 1;
                });
            } else {
                data.ownerId = userId;
                data.stock += parseInt(amount) || 1;
                (data.productOptions || []).forEach((op) => {
                    op.stock += parseInt(amount) || 1;
                });
            }
        }).then(() => {
            database.ref(`InvestProductOfShop/${userId}/${item.id}`).set(data);
        });
    }

    static getShopInvestList(shopId, callback) {
        const ref = database.ref(`InvestProductOfShop/${shopId}`);
        ref.once('value', (snapshot) => {
            let data = [];
            snapshot.forEach((childSnapshot) => {
                const childKey = childSnapshot.key;
                const childData = childSnapshot.val();
                data.push({
                    id: childKey,
                    ...childData,
                });
            });
            if (callback) {
                callback(data);
            }
        });
    }

    static getApartmentInfo(id, callback) {
        firestore
            .collection('apartments')
            .doc(id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    callback({ ...doc.data(), id: doc.id });
                    return;
                }
                callback(null);
            });
    }

    static getApartments(callback) {
        firestore
            .collection('apartments')
            .get()
            .then((querySnapshot) => {
                let data = [];
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });
                callback(data);
            });
    }
}

export class UserService {
    static getItemList(searchKey, callback) {
        database.ref(`BOShopList/`).once('value', (snapshot) => {
            let data = [];
            snapshot.forEach((childSnapshot) => {
                const childKey = childSnapshot.key;
                const childData = childSnapshot.val();
                Object.keys(childData).map((key) => {
                    if (childKey == 'admin' || childData[key].isPublished) {
                        data.push({
                            id: key,
                            ownerId: childKey,
                            ...childData[key],
                        });
                    }
                });
            });
            if (callback) {
                callback(data);
            }
        });
    }

    static login(username, password, callback) {
        firestore
            .collection('normalUsers')
            .where('username', '==', username)
            .where('password', '==', password)
            .get()
            .then((querySnapshot) => {
                if (querySnapshot.docs.length > 0) {
                    const doc = querySnapshot.docs[0];
                    callback({
                        id: doc.id,
                        ...doc.data(),
                    });
                    return;
                }
                callback(null);
            })
            .catch((error) => {
                callback(null);
            });
    }

    static register(username, password, onFailed, onSuccess) {
        firestore
            .collection('accounts')
            .where('username', '==', username)
            .get()
            .then((querySnapshot) => {
                if (querySnapshot.docs.length > 0) {
                    onFailed('Tên đăng nhập đã tồn tại');
                    return;
                }

                firestore
                    .collection('accounts')
                    .add({
                        username,
                        password,
                        contactNo: username,
                        role: ROLE.CUSTOMER,
                    })
                    .then((docRef) => {
                        onSuccess();
                    })
                    .catch((error) => {
                        console.error('Error adding document: ', error);
                    });
            })
            .catch((error) => {
                onFailed(null);
            });
    }

    static getCartRealtime(callback) {
        const ref = database.ref(`shopingCarts/${userId}/`);
        ref.on('value', (snapshot) => {
            callback(snapshot.val());
        });
    }

    static getCart(callback) {
        const ref = database.ref(`shopingCarts/${userId}/`);
        ref.on('value', (snapshot) => {
            callback(snapshot.val());
        });
    }

    static updateCart(data, itemId, quantity, selectedOption, callback) {
        let cart = { ...data };
        if (!data) {
            cart = {
                totalQuantity: quantity,
                items: [
                    {
                        id: itemId,
                        quantity,
                        productOptions: [
                            {
                                ...selectedOption,
                                quantity,
                            },
                        ],
                    },
                ],
            };
        } else {
            const hasItem = data.items.filter((item) => {
                if (item.id == itemId) {
                    return !!(item.productOptions || []).some((op) => op.id == selectedOption.id);
                }
                return false;
            })[0];
            if (hasItem) {
                cart.items = data.items.map((item) => {
                    if (item.id == itemId) {
                        item.quantity += quantity;
                        item.productOptions.forEach((op) => {
                            if (op.id == selectedOption.id) {
                                op.quantity += quantity;
                            }
                        });
                    }
                    return item;
                });
            } else {
                const hasRootItem = data.items.some((item) => item.id == itemId);
                if (hasRootItem) {
                    cart.items = data.items.map((item) => {
                        if (item.id == itemId) {
                            item.quantity += quantity;
                            item.productOptions = [
                                ...(item.productOptions || []),
                                {
                                    ...selectedOption,
                                    quantity,
                                },
                            ];
                        }
                        return item;
                    });
                } else {
                    cart.items.push({
                        id: itemId,
                        quantity,
                        productOptions: [
                            {
                                ...selectedOption,
                                quantity,
                            },
                        ],
                    });
                }
            }
            cart.totalQuantity += quantity;
        }
        const ref = database.ref(`shopingCarts/${userId}/`);
        ref.set(cart, (error) => {
            if (!error) {
                callback();
            }
        });
    }

    static removeCartItemList(data, itemIdList, callback) {
        let cart = { ...data };
        let cartItemn = [];
        let totalRemoveQuantity = 0;
        const product = cart.items.filter((item) => {
            const needRemove = itemIdList.some((id) => id === item.id);
            if (needRemove) {
                totalRemoveQuantity += item.quantity;
            }
            return !needRemove;
        });
        cart.totalQuantity = cart.totalQuantity - totalRemoveQuantity;
        cart.items = product;
        const ref = database.ref(`shopingCarts/${userId}/`);
        ref.set(cart, (error) => {
            if (!error) {
                if (callback) {
                    callback();
                }
            }
        });
    }

    static removeCartItem(data, id, selectedOption, callback) {
        let cart = { ...data };
        let cartItem = [];
        if (selectedOption) {
            cartItem = cart.items.filter((item) => item.id == id)[0];
            if (cartItem) {
                cart.totalQuantity = cart.totalQuantity - selectedOption.quantity;
            }

            const newCartItems = cart.items.map((item) => {
                if (item.id == id) {
                    const productOptions = item.productOptions.filter((op) => op.id !== selectedOption.id);
                    return {
                        ...item,
                        quantity: item.quantity - selectedOption.quantity,
                        productOptions,
                    };
                }
                return item;
            });
            cart.items = newCartItems;
        } else {
            const product = cart.items.filter((item) => item.id == id)[0];
            cart.totalQuantity = cart.totalQuantity - product.quantity;
            cartItem = cart.items.filter((item) => item.id !== id);
            cart.items = cartItem;
        }

        const ref = database.ref(`shopingCarts/${userId}/`);
        ref.set(cart, (error) => {
            if (!error) {
                callback();
            }
        });
    }

    static setUserData(username, userId) {
        localStorage.setItem('username', username);
        localStorage.setItem('userId', userId);
        localStorage.setItem('userType', 'normalUser');
    }

    static setCartCheckoutInfo(itemList, shipInfo) {
        const groupedItemList = groupBy(itemList, 'ownerId');
        const dataList = Object.keys(groupedItemList).map((key) => {
            return groupedItemList[key];
        });
        dataList.map((list) => {
            firestore
                .collection('orderList')
                .add({
                    itemList: list,
                    ownerId: userId,
                    shopId: list[0].ownerId,
                    shipInfo,
                    status: ORDER_STATUS.REQUESTING,
                })
                .then((docRef) => {})
                .catch((error) => {
                    console.error('Error adding document: ', error);
                });
        });
    }

    static getUserData(callback) {
        const userType = localStorage.getItem('userType');
        const userId = localStorage.getItem('userId');
        if (userType == 'normalUser') {
            firestore
                .collection('normalUsers')
                .doc(userId)
                .get()
                .then((querySnapshot) => {
                    if (callback) {
                        callback(querySnapshot.data());
                    }
                })
                .catch((error) => {});
            return;
        }

        if (userType == 'ShopUser') {
            firestore
                .collection('shopList')
                .doc(userId)
                .get()
                .then((querySnapshot) => {
                    if (callback) {
                        callback(querySnapshot.data());
                    }
                })
                .catch((error) => {});
            return;
        }
    }

    static setUserDataAfterCheckout(userData, totalPrice, address) {
        const data = { ...userData };
        data.balance = data.balance - totalPrice;
        data.address = address;
        firestore
            .collection('normalUsers')
            .doc(userId)
            .update(data)
            .then((docRef) => {})
            .catch((error) => {
                console.error('Error adding document: ', error);
            });
    }

    static addCustomCategory(cate) {
        const ref = database.ref(`CustomCategories/${userId}/${cate.id}`);
        ref.set({
            ...cate,
        });
    }

    static removeCustomCate(cate) {
        const ref = database.ref(`CustomCategories/${userId}/${cate.id}`);
        ref.remove().then(() => {});
    }

    static getCustomCategories(callback) {
        database.ref(`CustomCategories/${userId}`).on('value', (snapshot) => {
            let data = [];
            snapshot.forEach((childSnapshot) => {
                const childKey = childSnapshot.key;
                const childData = childSnapshot.val();
                data.push({
                    id: childKey,
                    ...childData,
                });
            });
            if (callback) {
                callback(data);
            }
        });
    }

    static submitProducComment(itemId, comment) {
        firestore.collection('comments').doc(itemId).collection('comments').add(comment);
    }

    static getProductComment(id, callback) {
        firestore
            .collection('comments')
            .doc(id)
            .collection('comments')
            .get()
            .then((querySnapshot) => {
                let data = [];
                querySnapshot.forEach((doc) => {
                    data.push({
                        id: doc.id,
                        ...doc.data(),
                    });
                });

                callback(data);
            });
    }

    static getChatOfUser() {
        return database.ref(`chat/${userId}`);
    }

    static submitChat(receiver, chatList) {
        if (!receiver) {
            return;
        }
        database.ref(`chat/${userId}/${receiver}`).set({ list: chatList || [] });
        database.ref(`chat/${receiver}/${userId}`).set({ list: chatList || [] });
    }

    static async addFriend(friendId) {
        const res = await firestore.collection('accounts').doc(userId).get();
        const otherUser = await firestore.collection('accounts').doc(friendId).get();
        if (res) {
            const userData = { ...res.data(), id: res.id };
            const newFriendList = [...(userData.friendIds || []), friendId];
            userData.friendIds = newFriendList;
            console.log(userData);
            firestore.collection('accounts').doc(userId).update(userData);
        }
        if (otherUser) {
            const userData = { ...otherUser.data(), id: otherUser.id };
            const newFriendList = [...(userData.friendIds || []), userId];
            userData.friendIds = newFriendList;

            firestore.collection('accounts').doc(friendId).update(userData);
        }
    }
}

export class DataJob {
    static addDemoChat() {
        const refA = database.ref(`chat/t1rbIPAKSvhZrUVEEIr6`);
        refA.set({
            wQkE0072CY7mTXEko4xl: {
                list: [
                    {
                        sender: 't1rbIPAKSvhZrUVEEIr6',
                        content: 'Hello',
                    },
                    {
                        sender: 'wQkE0072CY7mTXEko4xl',
                        content: 'Hi',
                    },
                ],
            },
        });
        const refB = database.ref(`chat/wQkE0072CY7mTXEko4xl`);
        refB.set({
            t1rbIPAKSvhZrUVEEIr6: {
                list: [
                    {
                        sender: 't1rbIPAKSvhZrUVEEIr6',
                        content: 'Hello',
                    },
                    {
                        sender: 'wQkE0072CY7mTXEko4xl',
                        content: 'Hi',
                    },
                ],
            },
        });
    }

    static backupItemList(items = [], index = 0) {
        const item = items[index];
        if (!item) {
            return;
        }
        const ref = database.ref(`BOShopList_backup/${item.ownerId}/${item.id}`);
        ref.set(item, (error) => {
            if (!error) {
                console.log('Backup success');
            }
            this.backupItemList(items, index + 1);
        });
    }
    static updateItemList(items, index = 0) {
        const item = items[index];
        if (!item) {
            return;
        }

        item.stock = parseInt(item.stock) > 1000 ? item.stock : 1000;
        item.orderType = ORDER_TYPE.READY;
        item.orderFrom = Area[0];
        item.productOptions = (item.productOptions || []).map((option) => {
            return {
                ...option,
                stock: parseInt(option.stock) > 1000 ? option.stock : 1000,
            };
        });
        const ref = database.ref(`BOShopList/${item.ownerId}/${item.id}`);
        ref.set(item, (error) => {
            if (!error) {
                console.log('Update success');
            }
            this.updateItemList(items, index + 1);
        });
    }
}

export const getGroupList = (callback) => {
    firestore
        .collection('apartments')
        .get()
        .then((querySnapshot) => {
            let data = [];
            querySnapshot.forEach((doc) => {
                data.push({
                    id: doc.id,
                    ...doc.data(),
                });
            });

            callback(data);
        });
};

export const getGroup = (id, callback) => {
    firestore
        .collection('apartments')
        .doc(id)
        .get()
        .then((doc) => {
            if (doc.exists) {
                callback({ ...doc.data(), id: doc.id });
                return;
            }
            callback(null);
        });
};

export const createGroup = (data, callback) => {
    firestore
        .collection('apartments')
        .add({ ...data, owner: userId })
        .then((docRef) => {
            console.log(docRef);
            callback(docRef);
        })
        .catch((error) => {
            console.error('Error adding document: ', error);
        });
};

export const updateGroup = (data, callback) => {
    firestore
        .collection('apartments')
        .doc(data.id)
        .update(data)
        .then((docRef) => {
            console.log(docRef);
            if (callback) callback(docRef);
        })
        .catch((error) => {
            console.error('Error adding document: ', error);
        });
};

export const getGroupManagerList = (callback) => {
    firestore
        .collection('accounts')
        .where('role', '==', 'group-manager')
        .get()
        .then((querySnapshot) => {
            let data = [];
            querySnapshot.forEach((doc) => {
                data.push({
                    id: doc.id,
                    ...doc.data(),
                });
            });

            callback(data);
        });
};

export const getGroupManager = (id, callback) => {
    firestore
        .collection('accounts')
        .doc(id)
        .get()
        .then((doc) => {
            if (doc.exists) {
                callback({ ...doc.data(), id: doc.id });
                return;
            }
            callback(null);
        });
};

export const createGroupmanager = (data, callback) => {
    firestore
        .collection('accounts')
        .add({ ...data })
        .then((docRef) => {
            callback();
        })
        .catch((error) => {
            console.error('Error adding document: ', error);
        });
};

export const updateGroupManager = (data, callback) => {
    firestore
        .collection('accounts')
        .doc(data.id)
        .update(data)
        .then((docRef) => {
            if (callback) callback();
        })
        .catch((error) => {
            console.error('Error adding document: ', error);
        });
};

export const getAllProvider = () => {
    return fetch('https://provinces.open-api.vn/api/p/').then((res) => res.json());
};

export const getDistrict = (provineCode) => {
    return fetch(`https://provinces.open-api.vn/api/p/${provineCode}?depth=2`).then((res) => res.json());
};

export const getWards = (districtCode) => {
    return fetch(`https://provinces.open-api.vn/api/d/${districtCode}?depth=2`).then((res) => res.json());
};

export const getAccountPromise = (id) => {
    console.log('Get', id);
    return new Promise(function (resolve, reject) {
        firestore
            .collection('accounts')
            .doc(id)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    console.log(doc.data());
                    return resolve({ ...doc.data(), id: doc.id });
                }
                return resolve(null);
            });
    });
};
