import { Button } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { useEffect, useState } from 'react';
import { BoService } from '../../services/Service';

export const FriendList = () => {
    const [accountss, setAccounts] = useState([]);
    const [userData, setUserData] = useState(null);
    const [filtedAccount, setFilteredAccount] = useState([]);
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        BoService.getAllAccount((res) => setAccounts(res));
        BoService.getShopAccount(userId, setUserData);
    }, []);

    useEffect(() => {
        if (!userData || !accountss) {
            return;
        }
        const list = accountss.filter((account) => [...(userData.friendIds || [])].find((id) => id === account.id));
        setFilteredAccount(list);
    }, [userData, accountss]);

    const accountList = (
        <>
            <div className="grid grid-cols-2 md:grid-colds-5 gap-2">
                {filtedAccount.map((account) => (
                    <div className="flex p-5">
                        <Avatar />
                        <div className="flex flex-col ml-3">
                            <span>{account.contactName || account.username}</span>
                            <div className="flex gap-2">
                                <a href={`/chat/${account.id}`}>
                                    <Button style={{ width: '100px' }} type="primary">
                                        Nhắn tin
                                    </Button>
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );

    return <div className="grid grid-cols-2 md:grid-colds-5 gap-2">{accountList}</div>;
};
