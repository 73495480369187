import { Alert, Button, Card, Divider, notification } from 'antd';
import { useHistory, useParams } from 'react-router';
import { ORDER_STATUS } from '../../constant';
import { BoService } from '../../services/Service';
import { formatMoney } from '../../utils/Utils';
import { Bill } from './Bill';

export const CheckoutPage = () => {
    const history = useHistory();
    let { shopId } = useParams();
    const cart = JSON.parse(localStorage.getItem('cart'));
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const apartmentInfo = JSON.parse(localStorage.getItem('apartmentInfo'));
    const backLink = shopId ? `/shop/${shopId}` : `/${apartmentInfo.id}`;

    const handleOrder = () => {
        if (shopId) {
            BoService.createShopOrder({ cart, userInfo, apartmentInfo, status: ORDER_STATUS.REQUESTING, isPaid: false }, () => {
                localStorage.setItem('cart', null);

                notification.success({
                    message: 'Đặt hàng thành công',
                    description: 'Đặt hàng thành công',
                });
                history.push(backLink);
            });
            return;
        }
        BoService.createOrder({ cart, userInfo, apartmentInfo, status: ORDER_STATUS.REQUESTING, isPaid: false }, () => {
            localStorage.setItem('cart', null);

            notification.success({
                message: 'Đặt hàng thành công',
                description: 'Đặt hàng thành công',
            });
            history.push(backLink);
        });
    };

    if (!cart) {
        <div className="flex-1">
            <Alert message="Không tìm thấy thông tin đơn hàng" type="error" />
        </div>;
    }

    return (
        <div className="flex justify-center flex-1 w-full p-3" style={{ backgroundColor: 'rgb(229, 231, 235)' }}>
            <div className="flex items-center w-full flex-col">
                <div className="w-full md:w-2/3">
                    <Bill userInfo={userInfo} cart={cart} />
                </div>
                <Button type="primary" className="mt-3 w-full md:w-2/3" onClick={handleOrder}>
                    Đặt hàng
                </Button>
                <Button type="ghost" className="mt-3 w-full md:w-2/3" href={backLink}>
                    Quay lại
                </Button>
            </div>
        </div>
    );
};
