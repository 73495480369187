// Initialize Firebase
import firebase from 'firebase';
require('firebase/firestore');

const config = {
    apiKey: 'AIzaSyCxYk3moi_o_ce8014MhPfqcBU_06tX-R4',
    authDomain: 'groupbuyoff.firebaseapp.com',
    projectId: 'groupbuyoff',
    databaseURL: 'https://groupbuyoff-default-rtdb.asia-southeast1.firebasedatabase.app',
    storageBucket: 'groupbuyoff.appspot.com',
    messagingSenderId: '946058542306',
    appId: '1:946058542306:web:85dbf6154675b55d1fb2bb',
    measurementId: 'G-YYSRYX8SHD',
};

var firebase_init = firebase.initializeApp(config);
export const Authprovider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const database = firebase.database();
export const storageRef = firebase.storage().ref();
export default firebase_init;
