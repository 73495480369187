import { Layout, Menu, Breadcrumb } from 'antd';
import {
    ApartmentOutlined,
    AreaChartOutlined,
    AuditOutlined,
    DiffOutlined,
    FileUnknownOutlined,
    HighlightOutlined,
    MailOutlined,
    ShoppingCartOutlined,
    UserOutlined,
    WindowsOutlined,
} from '@ant-design/icons';
import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory } from 'react-router-dom';
import { withRouter } from 'react-router';
import SocialPost from './social-post/SocialPost';
import PostListPage from './post-list/post-list-page';
import PostReview from '../components/post/PostReview';
import { ProductList } from '../components/product-manage/ProductList';
import { ProductForm } from '../components/product-manage/ProductForm';
import { OrderList } from '../components/orders/OrderList';
import { GroupList } from './group-list/GroupList';
import { GroupForm } from './group-list/GroupForm';
import { GroupManagerForm } from './group-manager/GroupManagerForm';
import { GroupManagerList } from './group-manager/GroupManagerList';
import { ShopForm } from './shop-list/ShopForm';
import { ShopList } from './shop-list/ShopList';
import { ShopProfile } from './shop-profile/shop-profile';
import { ROLE } from '../constant';
import { FollowShopList } from './followedShops/followShopList';
import { BoService } from '../services/Service';
import { ChatPage } from '../chat-page/ChatPage';
import { FriendList } from './friendsList/friendList';
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const BoPage = ({}) => {
    const [collapsed, onCollapse] = useState(true);
    const username = localStorage.getItem('username');
    const role = localStorage.getItem('role');
    const userData = JSON.parse(localStorage.getItem('userData'));
    const history = useHistory();
    if (!username) {
        history.push('/login');
        window.location.reload();
    }
    const DefaultRoute = {
        admin: <ProductList />,
        'group-manager': <OrderList />,
        [ROLE.SHOP]: <ProductList />,
    };

    const fetchAccountInfo = () => {
        BoService.login(`${userData.username}`, userData.password, (user) => {
            if (user) {
                BoService.setUserData(user.username, user.id, user);
                return;
            }
        });
    };

    useEffect(() => {
        fetchAccountInfo();
    }, []);

    const BoMenu = {
        admin: [
            <Menu.Item key="1" icon={<DiffOutlined />}>
                <Link to="/bo/products">Hàng hóa</Link>
            </Menu.Item>,
            <Menu.Item key="2" icon={<ShoppingCartOutlined />}>
                <Link to="/bo/orders">Đơn hàng</Link>
            </Menu.Item>,
            <Menu.Item key="3" icon={<AuditOutlined />}>
                <Link to="/bo/group-manager">Tài khoản quản lý nhóm</Link>
            </Menu.Item>,
            <Menu.Item key="4" icon={<AuditOutlined />}>
                <Link to="/bo/shops">Tài khoản Shop</Link>
            </Menu.Item>,
            <Menu.Item key="5" icon={<ApartmentOutlined />}>
                <Link to="/bo/groups">Nhóm mua</Link>
            </Menu.Item>,
        ],
        'group-manager': [
            <Menu.Item key="1" icon={<DiffOutlined />}>
                <Link to="/bo/products">Hàng hóa</Link>
            </Menu.Item>,
            <Menu.Item key="2" icon={<ShoppingCartOutlined />}>
                <Link to="/bo/orders">Đơn hàng</Link>
            </Menu.Item>,
        ],
        customer: [
            <Menu.Item key="2" icon={<ShoppingCartOutlined />}>
                <Link to="/bo/orders">Đơn hàng</Link>
            </Menu.Item>,
            <Menu.Item key="follow-shop" icon={<DiffOutlined />}>
                <Link to="/bo/followedShops">Shop theo dõi</Link>
            </Menu.Item>,
            <Menu.Item key="friends" icon={<MailOutlined />}>
                <Link to="/bo/friends">Bạn bè</Link>
            </Menu.Item>,
            <Menu.Item key="chat" icon={<MailOutlined />}>
                <Link to="/bo/chat">Chat</Link>
            </Menu.Item>,
        ],
        shop: [
            <Menu.Item key="1" icon={<DiffOutlined />}>
                <Link to="/bo/products">Hàng hóa</Link>
            </Menu.Item>,
            <Menu.Item key="2" icon={<ShoppingCartOutlined />}>
                <Link to="/bo/orders">Đơn hàng</Link>
            </Menu.Item>,
            <Menu.Item key="chat" icon={<MailOutlined />}>
                <Link to="/bo/chat">Chat</Link>
            </Menu.Item>,
            <Menu.Item key="3" icon={<UserOutlined />}>
                <Link to="/bo/shop-profile">Thông tin</Link>
            </Menu.Item>,
        ],
    };

    return (
        <div>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
                    <div className="logo" />
                    <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                        {BoMenu[role].map((item) => item)}
                    </Menu>
                </Sider>
                <Layout className="site-layout">
                    <Content style={{ margin: '0 16px' }}>
                        <Switch>
                            <Route path="/bo/shop-profile">
                                <ShopProfile />
                            </Route>
                            <Route path="/bo/products/new">
                                <ProductForm />
                            </Route>
                            <Route path="/bo/products/edit/:id">
                                <ProductForm />
                            </Route>
                            <Route path="/bo/products">
                                <ProductList />
                            </Route>
                            <Route path="/bo/orders">
                                <OrderList />
                            </Route>
                            <Route path="/bo/groups/new">
                                <GroupForm />
                            </Route>
                            <Route path="/bo/groups/edit/:id">
                                <GroupForm />
                            </Route>
                            <Route path="/bo/groups">
                                <GroupList />
                            </Route>
                            <Route path="/bo/group-manager/new">
                                <GroupManagerForm />
                            </Route>
                            <Route path="/bo/group-manager/edit/:id">
                                <GroupManagerForm />
                            </Route>
                            <Route path="/bo/group-manager">
                                <GroupManagerList />
                            </Route>
                            <Route path="/bo/shops/new">
                                <ShopForm />
                            </Route>
                            <Route path="/bo/shops/edit/:id">
                                <ShopForm />
                            </Route>
                            <Route path="/bo/shops">
                                <ShopList />
                            </Route>
                            <Route path="/bo/followedShops">
                                <FollowShopList />
                            </Route>
                            <Route path="/bo/chat">
                                <ChatPage />
                            </Route>
                            <Route path="/bo/friends">
                                <FriendList />
                            </Route>
                            <Route path="/bo">{DefaultRoute[role]}</Route>
                        </Switch>
                    </Content>
                </Layout>
            </Layout>
        </div>
    );
};

export default withRouter(BoPage);
