import React from 'react';
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { BoService } from '../services/Service';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export class ImageUploader extends React.Component {
    state = {
        previewVisible: false,
        previewImage: '',
        previewTitle: '',
        maxFiles: this.props.maxFiles || 1,
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle:
                file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    handleChange = ({ fileList }) => this.setState({ fileList });

    beforeUploadImage = (file) => {
        BoService.uploadItemImage(file, (url) =>
            this.props.onFinishUploadImage(file.name, url)
        );
    };

    onRemoveImage = (file) => {
        console.log(file);
        if (this.props.removeImage) {
            this.props.removeImage(file.uid);
            BoService.removeItemImage(file.name);
        }
    };

    render() {
        const { previewVisible, previewImage, previewTitle } = this.state;
        const fileList = this.props.imgList;
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );
        return (
            <>
                <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    beforeUpload={this.beforeUploadImage}
                    onRemove={this.onRemoveImage}
                    multiple={true}
                >
                    {fileList.length >= this.state.maxFile
                        ? null
                        : uploadButton}
                </Upload>
                <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <img
                        alt="example"
                        style={{ width: '100%' }}
                        src={previewImage}
                    />
                </Modal>
            </>
        );
    }
}
