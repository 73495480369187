import { Button, Badge, Modal, Input, notification } from 'antd';
import { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { AppContext } from '../App';
import { BoService, UserService } from '../services/Service';
import { ROLE } from '../constant';
export const BoHeader = ({}) => {
    const { context } = useContext(AppContext);
    const [registerModalVisible, setRegisterModalVisible] = useState(false);
    const [shopFormVisible, setShopFormVisible] = useState(false);
    const [regUserName, setRegUsername] = useState('');
    const [regPassword, setRegPassword] = useState('');
    const userName = localStorage.getItem('username');
    const userType = localStorage.getItem('userType');
    const userData = JSON.parse(localStorage.getItem('userData'));
    const apartmentInfo = JSON.parse(localStorage.getItem('apartmentInfo'));
    const [shopOpenData, setShopOpenData] = useState({ name: '', address: '' });
    const history = useHistory();

    const logout = () => {
        BoService.logout();
        window.location.reload();
    };

    const onRegister = () => {
        UserService.register(
            regUserName,
            regPassword,
            (error) => {
                notification.error({
                    message: 'Đăng kí',
                    description: error,
                });
            },
            () => {
                notification.success({
                    message: 'Đăng kí thành công',
                    description: 'Bạn đã đăng kí thành công',
                });
                setRegisterModalVisible(false);
            }
        );
    };

    const switchToShopAccount = () => {
        BoService.logout();
        BoService.login(`${userData.contactNo}-shop`, userData.password, (user) => {
            if (user) {
                BoService.setUserData(user.username, user.id, user);
                history.push('/bo');
                window.location.reload();
                return;
            }
        });
    };

    const switchToCustomerAccount = () => {
        BoService.logout();
        BoService.login(`${userData.contactNo}`, userData.password, (user) => {
            if (user) {
                BoService.setUserData(user.username, user.id, user);
                history.push('/bo');
                window.location.reload();
                return;
            }
        });
    };

    const onChangeValueShop = (field, value) => {
        const newProduct = { ...shopOpenData };
        newProduct[field] = value;

        setShopOpenData(newProduct);
    };

    const onShopRegister = () => {
        if (!shopOpenData.name || !shopOpenData.address) {
            return;
        }
        BoService.createShop({
            username: userData.contactNo + '-shop',
            password: userData.password,
            role: ROLE.SHOP,
            contactName: userData.username,
            contactNo: userData.contactNo,
            name: shopOpenData.name,
            address: shopOpenData.address,
            provine: null,
            enabled: true,
            shopOpenByCustomer: true,
        });
        BoService.updateShopAccount({ ...userData, shopOpened: true });
        localStorage.setItem('userData', JSON.stringify({ ...userData, shopOpened: true }));
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    };

    const regModal = (
        <Modal title="Đăng kí" visible={registerModalVisible} onOk={onRegister} onCancel={() => setRegisterModalVisible(false)}>
            <div>
                <span>Số điện thoại</span>
                <Input value={regUserName} onChange={(e) => setRegUsername(e.target.value)}></Input>
            </div>
            <div>
                <span>Mật khẩu</span>
                <Input type="password" value={regPassword} onChange={(e) => setRegPassword(e.target.value)}></Input>
            </div>
        </Modal>
    );

    const shopOpenModal = (
        <Modal title="Đăng kí shop" visible={shopFormVisible} onOk={onShopRegister} onCancel={() => setShopFormVisible(false)}>
            <div className="flex  mt-5">
                <div className="w-1/3">Tên shop</div>
                <Input value={shopOpenData.name} onChange={(e) => onChangeValueShop('name', e.target.value)}></Input>
            </div>
            <div className="flex  mt-5">
                <div className="w-1/3">Địa chỉ</div>
                <Input value={shopOpenData.address} onChange={(e) => onChangeValueShop('address', e.target.value)}></Input>
            </div>
        </Modal>
    );

    const openWallet = () => {
        if (window.ethereum) {
            window.ethereum.request({ method: 'eth_requestAccounts' });
        }
    };

    return (
        <div className="w-screen flex App-bo-header p-3 justify-between">
            {regModal}
            {shopOpenModal}
            {userName && (
                <div className="flex">
                    <div>
                        <Link to={`/`} className="mr-3">
                            Trang chủ
                        </Link>

                        <Link to="/bo" className="mr-3">
                            Quản lý
                        </Link>
                    </div>
                </div>
            )}
            {userName ? (
                <div className="flex items-center">
                    {userData && (
                        <div className="flex mr-2 gap-2 items-center">
                            <span>số dư: 0 GToken</span>{' '}
                            <Button type="primary" onClick={openWallet}>
                                Xem ví
                            </Button>
                        </div>
                    )}
                    {userData && userData.role === ROLE.CUSTOMER && !userData.shopOpened && (
                        <Button type="primary" className="mr-3" onClick={() => setShopFormVisible(true)}>
                            Đăng kí shop
                        </Button>
                    )}
                    {userData && userData.role === ROLE.CUSTOMER && userData.shopOpened && (
                        <Button type="primary" className="mr-3" onClick={switchToShopAccount}>
                            Quản lí shop
                        </Button>
                    )}
                    {userData && userData.role === ROLE.SHOP && userData.shopOpenByCustomer && (
                        <Button type="primary" className="mr-3" onClick={switchToCustomerAccount}>
                            Chế độ khách
                        </Button>
                    )}
                    <Button type="danger" className="mr-3" onClick={logout}>
                        Đăng xuất
                    </Button>
                </div>
            ) : (
                <div className="flex items-center">
                    <div>
                        <Link type="link" className="mr-3" onClick={() => setRegisterModalVisible(true)}>
                            Đăng kí
                        </Link>
                    </div>

                    <Button type="danger" href="/login" className="mr-3">
                        Đăng nhập
                    </Button>
                </div>
            )}
        </div>
    );
};
