import { useContext, useEffect, useState } from 'react';
import { ChatItem } from 'react-chat-elements';
import { AppContext } from '../App';
import { BoService } from '../services/Service';
export const ChatList = ({ chatData, onClickChat, currentReceiver }) => {
    const [accounts, setAccounts] = useState([]);
    const [chatList, setChatList] = useState([]);
    console.log(chatData, currentReceiver);

    useEffect(() => {
        BoService.getAllAccount((res) => {
            console.log(res);
            setAccounts(res || []);
        });
    }, []);

    useEffect(() => {
        let data = [];
        console.log('Chat data', chatData);
        if (chatData.length > 0) {
            data = chatData.map((chat) => {
                const receiver = chat.id;
                let receiverName = '';
                const chatFromReceiver = chat.list.find((item) => item.sender === receiver);
                if (chatFromReceiver) {
                    receiverName = chatFromReceiver.senderName;
                } else {
                    const shop = accounts.find((item) => item.id === receiver);
                    if (shop) {
                        receiverName = shop.name || shop.contactName || shop.username;
                    }
                }
                const lastedMessage = (chat.list[chat.list.length - 1] && chat.list[chat.list.length - 1].content) || '';

                return {
                    receiver,
                    receiverName,
                    lastMessage: lastedMessage,
                };
            });
        } else {
            const shop = accounts.find((item) => item.id === currentReceiver);
            console.log('infp', shop, accounts);
            const receiver = currentReceiver;
            let receiverName = '';
            if (shop) {
                receiverName = shop.name || shop.contactName || shop.username;
                data = [
                    {
                        receiver,
                        receiverName,
                        lastMessage: '',
                    },
                ];
                console.log(data);
            }
        }

        setChatList(data);
    }, [chatData, accounts, currentReceiver]);

    return (
        <div className="flex flex-col">
            {chatList.map((chat) => {
                return (
                    <ChatItem
                        avatar={'https://forum.waka.vn/assets/avatars/default.svg'}
                        alt={'Reactjs'}
                        title={chat.receiverName}
                        subtitle={chat.lastMessage}
                        date={chat.date}
                        unread={0}
                        className={`w-full ${currentReceiver === chat.receiver && 'active'}`}
                        onClick={() => onClickChat(chat.receiver)}
                    />
                );
            })}
        </div>
    );
};
