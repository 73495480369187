import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, message, Popconfirm } from 'antd';

export const ShopItem = ({ shop, onRemove }) => {
    const copyUrl = () => {
        const body = document.querySelector('body');
        const paragraph = document.querySelector('p');
        const area = document.createElement('textarea');
        body.appendChild(area);

        area.value = `https://muaban.app/shop/${shop.id}`;
        area.select();
        document.execCommand('copy');
        body.removeChild(area);
        message.success('Đã copy');
    };
    return (
        <Card hoverable>
            <div className="flex flex-col">
                <span>Tên đăng nhập: {shop.username}</span>
                <span>Mật khẩu: {shop.password}</span>
                <span>Tên: {shop.contactName}</span>
                <span>Số điện thoại: {shop.contactNo}</span>

                <div className="flex w-full justify-between">
                    <a href={`/bo/shops/edit/${shop.id}`} style={{ height: 20, fontSize: 20 }} className="mr-3">
                        <EditOutlined size={30} />
                    </a>

                    <div style={{ height: 20, fontSize: 20 }} className="mr-3">
                        <CopyOutlined size={30} onClick={copyUrl} />
                    </div>
                    {/* <div style={{ height: 20, fontSize: 20 }} className="mr-3">
                        <Popconfirm
                            placement="top"
                            title={'Xóa'}
                            onConfirm={() => onRemove(manager)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="text">
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </div> */}
                </div>
            </div>
        </Card>
    );
};
