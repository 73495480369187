import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, Popconfirm, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { ROLE } from '../../constant';
import { BoService } from '../../services/Service';
import { formatMoney } from '../../utils/Utils';

export const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [disbaleProducts, setDisableProducts] = useState([]);
    const role = localStorage.getItem('role');
    const userId = localStorage.getItem('userId');

    useEffect(() => {
        getProductList();
        if (role === ROLE.GROUP_MANAGER) {
            getDisableProduct();
        }
    }, []);

    const getDisableProduct = () => {
        BoService.getDisablePostOfManager(userId, (res) => {
            setDisableProducts((res && res.list) || []);
        });
    };

    const getProductList = () => {
        if (role === ROLE.SHOP) {
            BoService.getShopProduct((data) => {
                let list = data;
                list = list.filter((item) => item.owner === userId && !item.originId);
                setProducts(list);
            });
            return;
        }

        BoService.getPostList((data) => {
            let list = data;
            if (role === ROLE.GROUP_MANAGER) {
                list = list.filter((item) => item.enabled && !item.originId);
            }
            setProducts(list);
        });
    };

    const onRemove = (product) => {
        if (role === ROLE.SHOP) {
            BoService.removeShopProduct(product);
        } else {
            BoService.removePost(product);
        }
        const list = products.filter((p) => p.id !== product.id);
        setProducts(list);
    };

    const togglePublishProduct = (product, checked) => {
        if (role === ROLE.GROUP_MANAGER) {
            BoService.disbleManagerProduct(userId, disbaleProducts, product, checked, () => {
                getProductList();
                getDisableProduct();
            });
            return;
        }
        if (role === ROLE.SHOP) {
            BoService.toggleShopProduct(product, checked, () => getProductList());
            return;
        }
        BoService.togglePost(product, checked, () => getProductList());
    };
    return (
        <div className="flex flex-col">
            <Button type="primary" className="mt-2 w-full md:w-2/12 lg:w-2/12 xl:w-1/12" href="/bo/products/new">
                Thêm sản phẩm
            </Button>
            <div className="mt-2 grid grid-cols-1 gap-2 md:grid-cols-4 lg:grid-cols-5">
                {products.map((product) => {
                    return (
                        <Card hoverable>
                            <div className="w-full">
                                <div className="flex w-full justify-end">
                                    <Switch
                                        checked={product.enabled && !disbaleProducts.some((proId) => proId === product.id)}
                                        onChange={(checked) => togglePublishProduct(product, checked)}
                                    />
                                </div>
                                <div>
                                    <img
                                        src={
                                            (product.imgs && product.imgs[0] && product.imgs[0].url) ||
                                            'https://www.publicdomainpictures.net/pictures/280000/velka/not-found-image-15383864787lu.jpg'
                                        }
                                        style={{ height: 100, width: 100, objectFit: 'cover' }}
                                        alt="img"
                                    />
                                    <div>
                                        <span className="text-base font-bold">{product.name}</span>
                                        <div className="flex justify-between">
                                            <span>Đơn vị: {product.unit}</span>
                                            <span>Đơn giá: {formatMoney(product.price)}</span>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ marginLeft: 'auto' }} className="flex justify-between">
                                    <a
                                        href={`/bo/products/edit/${product.id}`}
                                        style={{ height: 20, fontSize: 20 }}
                                        className="mr-3"
                                    >
                                        <EditOutlined size={30} />
                                    </a>
                                    <div style={{ height: 20, fontSize: 20 }}>
                                        <Popconfirm
                                            placement="top"
                                            title={'Xóa'}
                                            onConfirm={() => onRemove(product)}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button type="text">
                                                <DeleteOutlined />
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    );
                })}
            </div>
        </div>
    );
};
