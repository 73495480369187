export const NO_IMAGE_URL = 'https://phapluattoandan.com/wp-content/themes/consultix/images/no-image-found-360x250.png';
export const ORDER_TYPE = {
    READY: 'ready',
    ORDER_FROM: 'order_from',
};
export const Currencies = ['VND', 'NDT'];

export const ADS_POSITION = [
    {
        position: 'pc-1',
        height: '550px',
    },
    {
        position: 'pc-2',
        height: '200px',
    },
    {
        position: 'pc-3',
        height: '450px',
    },
    {
        position: 'pc-4',
        height: '550px',
    },
    {
        position: 'mb-1',
        height: '100px',
    },
    {
        position: 'mb-2',
        height: '100px',
    },
    {
        position: 'mb-3',
        height: '100px',
    },
];

export const CART_TYPE = {
    READY_TO_SHIP: 'READY_TO_SHIP',
    READY_TO_ORDER: 'READY_TO_ORDER',
    WAIT_TO_ORDER: 'WAIT_TO_ORDER',
};

export const ORDER_STATUS = {
    REQUESTING: 'REQUESTING',
    PROCESSING: 'PROCESSING',
    SHIPPING: 'SHIPPING',
    DONE: 'DONE',
};

export const ITEM_LIST_MODE = {
    INVEST: 'invest-list',
};

export const ROLE = {
    ADMIN: 'admin',
    GROUP_MANAGER: 'group-manager',
    CUSTOMER: 'customer',
    SHOP: 'shop',
};
