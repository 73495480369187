import { Form, Input, Button, Space, Select, notification, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { ImageUploader } from '../components/ImageUploader';
import { ORDER_TYPE } from '../constant';
import { BoService } from '../services/Service';
import { v4 as uuidv4 } from 'uuid';
import Modal from 'antd/lib/modal/Modal';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import { Currencies } from '../constant';
const { Option } = Select;
export const Area = ['Việt Nam', 'Trung Quốc'];
const OrderType = [
    { id: ORDER_TYPE.READY, desc: 'Hàng có sẵn' },
    { id: ORDER_TYPE.ORDER_FROM, desc: 'Đăt hàng số lượng' },
];
const userId = localStorage.getItem('userId');

const AreaSelect = ({ label, value, onChange }) => {
    return (
        <div className="flex items-center mb-3">
            <span className="mr-2 w-1/3">{label}</span>
            <Select value={value} onChange={onChange} className="flex-1">
                {Area.map((area) => (
                    <Option value={area}>{area}</Option>
                ))}
            </Select>
        </div>
    );
};

const CurrencySelect = ({ amountValue, curValue, onChangeCur, onChangeAmount }) => {
    return (
        <div className="flex items-center mb-3">
            <Input value={amountValue} onChange={(ev) => onChangeAmount(ev.target.value)}></Input>
            <Select value={curValue} onChange={onChangeCur} className="flex-1">
                {Currencies.map((cur) => (
                    <Option value={cur}>{cur}</Option>
                ))}
            </Select>
        </div>
    );
};

const ProductionOptionSelect = ({ root, addItem }) => {
    const [newOptionName, setNewOptionName] = useState('');

    const onNameChange = (event) => {
        setNewOptionName(event.target.value);
    };

    const handleAddItem = () => {
        if (!!newOptionName) {
            addItem(root.id, newOptionName);
            setNewOptionName('');
        }
    };

    return (
        <div className="flex flex-col mt-2">
            <span className="mb-1">{root.name}</span>
            <Select
                style={{ width: 240 }}
                placeholder={root.name}
                dropdownRender={(menu) => (
                    <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'nowrap',
                                padding: 8,
                            }}
                        >
                            <Input style={{ flex: 'auto' }} value={newOptionName} onChange={onNameChange} />
                            <a
                                style={{
                                    flex: 'none',
                                    padding: '8px',
                                    display: 'block',
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleAddItem()}
                            >
                                <PlusOutlined /> Thêm tùy chọn
                            </a>
                        </div>
                    </div>
                )}
            >
                {root.options.map((item) => (
                    <Option key={item.id}>{item.name}</Option>
                ))}
            </Select>
        </div>
    );
};

const ProductOptionsCreator = ({ options, addOption, addOptionItem }) => {
    const [optionName, setOptionName] = useState('');

    const handlerAddOption = () => {
        if (!!optionName) {
            addOption(optionName);
            setOptionName('');
        }
    };

    return (
        <div className="flex items-center mb-3">
            <span className="mr-2 w-1/3">Tùy chọn</span>
            <div className="flex-1 flex flex-col">
                {options.map((option) => (
                    <ProductionOptionSelect key={option.id} root={option} addItem={addOptionItem} />
                ))}
                <div className="mt-5 flex flex-col" style={{ width: '240px' }}>
                    <Input value={optionName} onChange={(e) => setOptionName(e.target.value)}></Input>
                    <Button type="dashed" onClick={handlerAddOption} className="mt-2">
                        Thêm tùy chọn
                    </Button>
                </div>
            </div>
        </div>
    );
};

const ProductOptionCreator = ({ options, productOptions, onSaveProductOption, imgList }) => {
    const [visibleModal, setVisibleModal] = useState(false);
    const [tagsData, setTagsData] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [inputData, setInputData] = useState({});

    const createOptions = (arr, keyStr, propStr) => {
        if (arr.length == 0) return [];
        if (arr.length == 1) {
            const res = [];
            arr[0].options.map((op) => {
                res.push({
                    id: [...keyStr, op.id].join(':'),
                    name: [...propStr, op.name].join('/'),
                });
            });
            return res;
        }
        let res = [];
        arr[0].options.map((op) => {
            const apKey = [...keyStr, op.id];
            const apProp = [...propStr, op.name];
            const nextArr = arr.slice(1);
            res = [...createOptions(nextArr, apKey, apProp), ...res];
        });
        return res;
    };

    useEffect(() => {
        let isMounted = true;
        const optionsData = createOptions(options, [], []);
        optionsData.forEach((op) => {
            const data = productOptions.filter((po) => po.id == op.id)[0];
            op.salePrice = (data && data.salePrice) || 0;
            op.stock = (data && data.stock) || 0;
            op.imageIndex = (data && data.imageIndex) || 0;
        });

        const inputData = {};
        optionsData.map((op) => {
            inputData[op.id] = {
                stock: op.stock,
                salePrice: op.salePrice,
                imageIndex: op.imageIndex,
            };
        });

        if (isMounted) {
            setTagsData(optionsData);
            setInputData(inputData);
        }

        return () => {
            isMounted = false;
        };
    }, [options]);

    options.map((option) => {
        const rootName = option.name;
    });

    const handleProductionOptionValueChange = (type, value) => {
        const newData = { ...inputData };
        newData[selectedOption][type] = value;
        setInputData(newData);
    };

    const handleUpdateProductionOptions = () => {
        let updatedData = [...tagsData];
        updatedData = updatedData.map((op) => {
            const data = inputData[op.id];
            const newOp = { ...op };
            newOp.salePrice = (data && parseInt(data.salePrice)) || 0;
            newOp.stock = (data && parseInt(data.stock)) || 0;
            newOp.imageIndex = (data && parseInt(data.imageIndex)) || 0;
            return newOp;
        });
        onSaveProductOption(updatedData);
        setVisibleModal(false);
    };

    if (options.length == 0) {
        return <div>Không có tùy chọn</div>;
    }

    return (
        <div className="flex items-center mb-3">
            <Modal
                title="Tùy chọn sản phẩm"
                visible={visibleModal}
                onOk={handleUpdateProductionOptions}
                onCancel={() => setVisibleModal(false)}
            >
                <div className="mb-2 flex flex-col">
                    <span className="font-bold">Tùy chọn</span>
                    <div>
                        {tagsData.map((tag) => (
                            <CheckableTag
                                key={tag.id}
                                checked={selectedOption == tag.id}
                                onChange={() => setSelectedOption(tag.id)}
                            >
                                {tag.name}
                            </CheckableTag>
                        ))}
                    </div>
                </div>

                {inputData[selectedOption] && (
                    <div>
                        <div className="mb-2">
                            <span className="font-bold">Giá</span>
                            <Input
                                value={inputData[selectedOption].salePrice}
                                onChange={(e) => handleProductionOptionValueChange('salePrice', e.target.value)}
                            ></Input>
                        </div>
                        <div className="mb-2">
                            <span className="font-bold">Số lượng trong kho</span>
                            <Input
                                value={inputData[selectedOption].stock}
                                onChange={(e) => handleProductionOptionValueChange('stock', e.target.value)}
                            ></Input>
                        </div>
                        <div className="mb-2">
                            <span className="font-bold">Hình ảnh</span>
                            <div className="grid grid-cols-4 gap-3">
                                {imgList.map((img, index) => {
                                    return (
                                        <img
                                            style={{
                                                width: '100px',
                                                height: '100px',
                                                objectFit: 'cover',
                                                borderWidth: inputData[selectedOption].imageIndex == index ? 7 : 0,
                                                borderColor: 'blueviolet',
                                            }}
                                            key={img.uid}
                                            src={img.url}
                                            onClick={() => handleProductionOptionValueChange('imageIndex', index)}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
            <span className="mr-2 w-1/3">Tùy chọn sản phẩm</span>

            <div className="mt-5 flex flex-col" style={{ width: '240px' }}>
                <Button type="primary" onClick={() => setVisibleModal(true)}>
                    Thêm
                </Button>
            </div>
        </div>
    );
};

export const NewItemForm = ({ setPageMode, itemData, onSave, onUpdate }) => {
    const [categories, setCategories] = useState([]);
    const [shopCategories, setShopCategories] = useState([]);
    const userType = localStorage.getItem('userType');

    const [madeIn, setMadeIn] = useState(Area[1]);
    const [orderFrom, setOrderFrom] = useState(Area[1]);
    const [saleTo, setSaleTo] = useState(Area[0]);
    const [itemName, setItemName] = useState('');
    const [linkOrder, setLinkOrder] = useState('');
    const [originalPrice, setOriginalPrice] = useState(0);
    const [originalCur, setOriginalCur] = useState(Currencies[0]);
    const [salePrice, setSalePrice] = useState(0);
    const [saleCur, setSaleCur] = useState(Currencies[0]);
    const [desc, setDesc] = useState('');
    const [imgList, setImgList] = useState([]);
    const [dimension, setDimension] = useState({
        height: '',
        width: '',
        length: '',
        weigth: '',
    });
    const [cates, setCates] = useState([]);
    const [shopCates, setShopCates] = useState([]);
    const [orderType, setOrderType] = useState(OrderType[0].id);
    const [stock, setStock] = useState(100);
    const [minOrder, setMinOrder] = useState(0);
    const [options, setOptions] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [salePercent, setSalePercent] = useState(0);

    const updateDimension = (de, value) => {
        const newDe = { ...dimension };
        newDe[de] = value;
        setDimension(newDe);
    };

    const onCategoryChange = (e) => {
        const list = [...cates];
        list.push(e);
        setCates(list);
    };

    const onShopCategoryChange = (e) => {
        const list = [...shopCates];
        list.push(e);
        setShopCates(list);
    };

    const onDeselectCate = (e) => {
        let list = [...cates];
        list = list.filter((cate) => cate !== e);
        setCates(list);
    };

    const onDeselectShopCate = (e) => {
        let list = [...shopCates];
        list = list.filter((cate) => cate !== e);
        setShopCates(list);
    };

    const mapItemCategories = (list = []) => {
        const newList = list.map((item) => item.id);
        setCates(newList);
    };

    const setCategorySelections = (list = []) => {
        setCategories(list);
        if (!itemData) {
            return;
        }
        const cates = (itemData.categories || []).map((item) => item.id);
        const newCates = cates.filter((cate) => list.filter((item) => item.id == cate).length >= 0);
        setCates(newCates);
    };

    const setShopCategorySelections = (list = []) => {
        setShopCategories(list);
        if (!itemData) {
            return;
        }
        const cates = (itemData.shopCategories || []).map((item) => item.id);
        const newCates = cates.filter((cate) => list.filter((item) => item.id == cate).length >= 0);
        setShopCates(newCates);
    };

    const onOrderTypeChange = (e) => {
        setOrderType(e);
    };

    const onOriginalPriceChange = (amount) => {
        const value = parseFloat(amount) || 0;
        setSalePrice((value * salePercent) / 100);
        setOriginalPrice(amount);
    };

    const onSalePercentChange = (amount) => {
        const value = parseInt(amount) || 0;
        setSalePrice((value * originalPrice) / 100);
        setSalePercent(value);
    };

    const onRemoveImage = (imageId) => {
        const newImgList = imgList.filter((img) => img.uid !== imageId);
        setImgList(newImgList);

        const itemCates = cates.map((cate) => categories.filter((c) => c.id == cate)[0]);
        const itemShopCates = shopCates.map((cate) => shopCategories.filter((c) => c.id == cate)[0]);

        const data = {
            id: itemData && itemData.id,
            madeIn,
            orderFrom,
            saleTo,
            itemName,
            linkOrder,
            imgList: newImgList,
            originalPrice,
            originalCur,
            salePrice,
            saleCur,
            desc,
            dimension,
            categories: itemCates,
            shopCategories: itemShopCates,
            orderType,
            stock,
            minOrder,
            options,
            productOptions,
            ownerId: userId,
        };
        if (onUpdate) {
            onUpdate(data);
        } else {
            BoService.updateProduct(data);
        }
    };

    useEffect(() => {
        if (itemData) {
            setMadeIn(itemData.madeIn);
            setOrderFrom(itemData.orderFrom);
            setSaleTo(itemData.saleTo);
            setItemName(itemData.itemName);
            setLinkOrder(itemData.linkOrder);
            setOriginalPrice(itemData.originalPrice);
            setOriginalCur(itemData.originalCur);
            setSalePrice(itemData.salePrice || 0);
            setSaleCur(itemData.saleCur || Currencies[0]);
            setDesc(itemData.desc);
            setImgList(itemData.imgList || []);
            setDimension(
                itemData.dimension || {
                    height: '',
                    width: '',
                    length: '',
                    weigth: '',
                }
            );
            mapItemCategories(itemData.categories || []);
            setStock(itemData.stock || 0);
            setMinOrder(itemData.minOrder || 0);
            setOrderType(itemData.orderType || OrderType[0].id);
            setOptions(itemData.options || []);
            setProductOptions(itemData.productOptions || []);
            console.log(itemData);
            const salePrice = parseInt(itemData.salePrice) || 0;
            const originalPrice = parseInt(itemData.originalPrice) || 1;
            setSalePercent(Math.round((salePrice * 100) / originalPrice).toString());
        }
        setTimeout(() => {
            BoService.getCategoriesOfAdmin(setCategorySelections);
            BoService.getCategories(setShopCategorySelections);
        }, 200);
    }, []);

    useEffect(() => {
        if (categories.length > 0) {
            BoService.getProductList((list) => {
                const latestItem = list[list.length - 1];
                if (latestItem && !itemData) {
                    setMadeIn(latestItem.madeIn);
                    setOrderFrom(latestItem.orderFrom);
                    setSaleTo(latestItem.saleTo);
                    setOriginalCur(latestItem.originalCur);
                    setSaleCur(latestItem.saleCur || Currencies[0]);
                    mapItemCategories(latestItem.categories || []);
                    setStock(latestItem.stock || 0);
                    setMinOrder(latestItem.minOrder || 0);
                    setOrderType(latestItem.orderType || OrderType[0].id);
                    const salePrice = parseInt(latestItem.salePrice) || 0;
                    const originalPrice = parseInt(latestItem.originalPrice) || 1;
                    setSalePercent(Math.round((salePrice * 100) / originalPrice).toString());

                    const cates = (latestItem.shopCategories || []).map((item) => item.id);
                    const newCates = cates.filter((cate) => list.filter((item) => item.id == cate).length >= 0);
                    setShopCates(newCates);
                }
            });
        }
    }, [categories]);

    const onSuccess = () => {
        notification['success']({
            message: 'Đăng thành công',
            description: 'Bạn đã đăng sản phẩm thành công',
        });
        setPageMode('item-list');
    };

    const createNewItem = () => {
        let callFunc;
        const itemCates = cates.map((cate) => categories.filter((c) => c.id == cate)[0]);
        const itemShopCates = shopCates.map((cate) => shopCategories.filter((c) => c.id == cate)[0]);
        if (!itemData) {
            const data = {
                madeIn,
                orderFrom,
                saleTo,
                itemName,
                linkOrder,
                imgList,
                originalPrice: parseFloat(originalPrice) || 0,
                originalCur,
                salePrice,
                saleCur,
                desc,
                dimension,
                categories: itemCates,
                shopCategories: itemShopCates,
                orderType,
                stock,
                minOrder,
                options,
                productOptions,
                ownerId: userId,
            };
            if (onSave) {
                onSave(data, onSuccess);
            } else {
                BoService.createProduct(data, onSuccess);
            }
        } else {
            const data = {
                id: itemData && itemData.id,
                madeIn,
                orderFrom,
                saleTo,
                itemName,
                linkOrder,
                imgList,
                originalPrice: parseFloat(originalPrice) || 0,
                originalCur,
                salePrice,
                saleCur,
                desc,
                dimension,
                categories: itemCates,
                shopCategories: itemShopCates,
                orderType,
                stock,
                minOrder,
                options,
                productOptions,
                ownerId: userId,
            };
            if (onUpdate) {
                onUpdate(data, onSuccess);
            } else {
                BoService.updateProduct(data, onSuccess);
            }
        }
    };

    const onFinishUploadImage = (name, url) => {
        const newImg = {
            name,
            uid: name,
            url,
        };
        const newList = [...imgList, newImg];
        setImgList(newList);
    };

    const addOption = (optionName) => {
        const key = uuidv4();
        const newOptions = [...options, { id: key, name: optionName, options: [] }];
        setOptions(newOptions);
    };

    const addOptionsItem = (rootId, optionName) => {
        const index = options.findIndex((op) => op.id == rootId);
        if (index >= 0) {
            const key = uuidv4();
            const item = options[index];
            const data = { ...item };
            data.options.push({ id: key, name: optionName });
            const newOptions = [...options];
            newOptions.splice(index, 1, data);
            setOptions(newOptions);
        }
    };

    return (
        <div>
            <ImageUploader
                onFinishUploadImage={onFinishUploadImage}
                imgList={imgList}
                maxFiles={50}
                removeImage={onRemoveImage}
            />
            <AreaSelect label="Xuất xứ sản phẩm" value={madeIn} onChange={setMadeIn} />
            <AreaSelect label="Mua hàng từ quốc gia" value={orderFrom} onChange={setOrderFrom} />
            <AreaSelect label="Hàng được bán đến" value={saleTo} onChange={setSaleTo} />
            <div className="flex items-center mb-3">
                <span className="mr-2 w-1/3">Tên sản phẩm</span>
                <Input className="flex-1" value={itemName} onChange={(ev) => setItemName(ev.target.value)}></Input>
            </div>
            <div className="flex items-center mb-3">
                <span className="mr-2 w-1/3">Danh mục</span>
                <Select
                    className="flex-1"
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Chọn danh mục"
                    value={cates}
                    onSelect={onCategoryChange}
                    onDeselect={onDeselectCate}
                >
                    {categories.map((cate) => (
                        <Option key={cate.id}>{cate.name}</Option>
                    ))}
                </Select>
            </div>

            {userType === 'ShopUser' && (
                <div className="flex items-center mb-3">
                    <span className="mr-2 w-1/3">Danh mục của shop</span>
                    <Select
                        className="flex-1"
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Chọn danh mục"
                        value={shopCates}
                        onSelect={onShopCategoryChange}
                        onDeselect={onDeselectShopCate}
                    >
                        {shopCategories.map((cate) => (
                            <Option key={cate.id}>{cate.name}</Option>
                        ))}
                    </Select>
                </div>
            )}

            <div className="flex items-center mb-3">
                <span className="mr-2 w-1/3">Link nhập</span>
                <Input className="flex-1" value={linkOrder} onChange={(ev) => setLinkOrder(ev.target.value)}></Input>
            </div>
            <div className="flex items-center mb-3">
                <span className="mr-2 w-1/3">Kiểu đặt hàng</span>
                <Select className="flex-1" style={{ width: '100%' }} value={orderType} onSelect={onOrderTypeChange}>
                    {OrderType.map((type) => (
                        <Option key={type.id}>{type.desc}</Option>
                    ))}
                </Select>
            </div>
            {orderType == ORDER_TYPE.READY && (
                <div className="flex items-center mb-3">
                    <span className="mr-2 w-1/3">Số lượng trong kho</span>
                    <Input className="flex-1" value={stock} onChange={(ev) => setStock(ev.target.value)}></Input>
                </div>
            )}
            {orderType == ORDER_TYPE.ORDER_FROM && (
                <div className="flex items-center mb-3">
                    <span className="mr-2 w-1/3">Đặt mua từ số lượng</span>
                    <Input className="flex-1" value={minOrder} onChange={(ev) => setMinOrder(ev.target.value)}></Input>
                </div>
            )}
            <div className="flex items-center mb-3">
                <span className="mr-2 w-1/3">Giá gốc</span>
                <CurrencySelect
                    amountValue={originalPrice}
                    onChangeAmount={onOriginalPriceChange}
                    curValue={originalCur}
                    onChangeCur={setOriginalCur}
                />
            </div>
            <div className="flex items-center mb-3">
                <span className="mr-2 w-1/3">Phần trăm bán(%)</span>
                <CurrencySelect
                    amountValue={salePercent}
                    onChangeAmount={onSalePercentChange}
                    curValue={saleCur}
                    onChangeCur={setSaleCur}
                />
            </div>
            <div className="flex items-center mb-3">
                <span className="mr-2 w-1/3">Miêu tả</span>
                <Input.TextArea
                    className="flex-1"
                    value={desc}
                    onChange={(ev) => setDesc(ev.target.value)}
                ></Input.TextArea>
            </div>
            <div className="grid grid-cols-4 gap-3 mb-3">
                <div>
                    <span>Chiều cao</span>
                    <Input
                        value={dimension.height}
                        onChange={(ev) => updateDimension('height', ev.target.value)}
                    ></Input>
                </div>
                <div>
                    <span>Chiều dài</span>
                    <Input
                        value={dimension.length}
                        onChange={(ev) => updateDimension('length', ev.target.value)}
                    ></Input>
                </div>
                <div>
                    <span>Chiều rộng</span>
                    <Input value={dimension.width} onChange={(ev) => updateDimension('width', ev.target.value)}></Input>
                </div>
                <div>
                    <span>Cân nặng</span>
                    <Input
                        value={dimension.weight}
                        onChange={(ev) => updateDimension('weight', ev.target.value)}
                    ></Input>
                </div>
            </div>
            <ProductOptionsCreator options={options} addOption={addOption} addOptionItem={addOptionsItem} />
            <ProductOptionCreator
                options={options}
                productOptions={productOptions}
                onSaveProductOption={setProductOptions}
                imgList={imgList}
            ></ProductOptionCreator>
            <Button type="primary" className="mr-5" onClick={createNewItem}>
                {itemData ? 'Sửa thông tin' : 'Thêm sản phẩm'}
            </Button>
            <Button onClick={() => setPageMode('item-list')}>Hủy</Button>
        </div>
    );
};
