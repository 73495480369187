import { SearchOutlined } from '@ant-design/icons';
import { Card, Modal, Input, Space, Button, Drawer } from 'antd';
import { useEffect, useState } from 'react';
import { NO_IMAGE_URL } from '../../constant';
import { escapeRegExp, formatMoney, stringToSlug } from '../../utils/Utils';
import { ProductItem } from './ProductItem';

export const ItemPickModal = ({ visible, products, carts, onSelect, onClose }) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [searchKey, setSearchKey] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    useEffect(() => {
        let list = [...(products || [])];
        if (!searchKey) {
            setFilteredProducts(list);
            return;
        }
        const formatedKey = stringToSlug(searchKey);
        const query = escapeRegExp(formatedKey);
        const regex = new RegExp(`${query.trim()}`, 'i');
        list = products.filter((product) => {
            const name = stringToSlug(product.name);
            console.log(name, query, name.search(regex));
            return name.search(regex) >= 0;
        });
        setFilteredProducts(list);
    }, [searchKey, products]);

    return (
        <Drawer
            title="Chọn sản phẩm"
            placement={'bottom'}
            closable={true}
            onClose={onClose}
            visible={visible}
            key={'bottom'}
            height={'100vh'}
        >
            <Input
                className="mt-5"
                value={searchKey}
                placeholder="Nhập từ khóa tìm kiếm"
                onChange={(e) => setSearchKey(e.target.value)}
                prefix={<SearchOutlined />}
            />
            <div
                className="grid gap-2 grid-cols-2 md:grid-cols-3 lg:grid-cols-8 mt-5"
                style={{ maxHeight: '80%', overflow: 'auto' }}
            >
                {' '}
                {(filteredProducts || []).map((product) => {
                    const isAlreadInCart = (carts || []).some((cart) => cart.id === product.id);
                    return (
                        <ProductItem
                            product={product}
                            isNoneSelectable={isAlreadInCart}
                            isSelected={selectedProduct && selectedProduct.id === product.id}
                            onSelected={() => setSelectedProduct(product)}
                        />
                    );
                })}
            </div>
            <div className="mt-5 flex justify-between">
                <Button
                    onClick={() => {
                        const selected = { ...selectedProduct };
                        onSelect(selected);
                        setSelectedProduct(null);
                    }}
                    type="primary"
                >
                    Chọn
                </Button>
                <Button onClick={onClose}>Hủy</Button>
            </div>
        </Drawer>
    );
};
