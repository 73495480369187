import { Card, Divider } from 'antd';
import { NO_IMAGE_URL } from '../../constant';
import { formatMoney } from '../../utils/Utils';

export const Bill = ({ cart, userInfo, apartmentInfo }) => {
    if (!cart) {
        return;
    }
    let totalPrice = 0;
    (cart || []).map((item) => {
        totalPrice += item.price * item.quantity;
    });
    return (
        <>
            {userInfo && (
                <Card className="w-full" style={{ height: 'fit-content' }}>
                    <span className="font-bold">
                        Tên khách hàng: <span className="font-normal">{userInfo.name}</span>
                    </span>
                    <br />
                    <span className="font-bold">
                        SDT: <span className="font-normal">{userInfo.phone}</span>
                    </span>
                    <br />
                    <span className="font-bold">
                        Địa chỉ nhận hàng: <span className="font-normal">{userInfo.room}</span>
                    </span>
                    <br />
                    <span className="font-bold">
                        Tên cửa hàng: <span className="font-normal">{apartmentInfo && apartmentInfo.name}</span>
                    </span>
                    <br />
                    <span className="font-bold">
                        địa chỉ lấy hàng: <span className="font-normal">{apartmentInfo && apartmentInfo.address}</span>
                    </span>
                </Card>
            )}
            <Card className="w-full" style={{ height: 'fit-content' }}>
                {(cart || []).map((item) => {
                    return (
                        <div key={item.id}>
                            <div className="flex justify-between">
                                <div className="flex items-center">
                                    <img
                                        alt="example"
                                        style={{
                                            width: '50px',
                                            height: '50px',
                                            objectFit: 'cover',
                                        }}
                                        className="mr-2"
                                        src={(item.imgs && item.imgs[0] && item.imgs[0].url) || NO_IMAGE_URL}
                                    />
                                    <div>
                                        <span className="font-bold">{item.name}</span>
                                        <br />
                                        <span className="font-normal">
                                            Số lượng:{' '}
                                            <span className="font-bold">
                                                {item.quantity}({item.unit})
                                            </span>
                                        </span>
                                    </div>
                                </div>

                                <span className="font-bold">{formatMoney(item.price * item.quantity)}</span>
                            </div>
                            <Divider />
                        </div>
                    );
                })}
                <div className="flex justify-end">
                    <span className="font-bold">
                        Tổng cộng <span className="text-lg">{formatMoney(totalPrice)}</span>
                    </span>
                </div>
            </Card>
        </>
    );
};
