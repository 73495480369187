import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { getGroupList } from '../../services/Service';
import { GroupItem } from './GroupItem';

export const GroupList = () => {
    const [groups, setGroups] = useState([]);
    useEffect(() => {
        getGroupList((res) => setGroups(res));
    }, []);
    return (
        <div className="flex p-2 flex-col">
            <Button type="primary" className="mt-2 w-full md:w-2/12 lg:w-2/12 xl:w-1/12" href="/bo/groups/new">
                Thêm nhóm
            </Button>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-3 mt-2">
                {groups.map((group) => {
                    return <GroupItem key={group.id} group={group} />;
                })}
            </div>
        </div>
    );
};
