import { CheckOutlined, CloseOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Avatar, Button, Card, Image, Input, InputNumber, message, Select, Spin, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { NO_IMAGE_URL, ROLE } from '../../constant';
import { BoService } from '../../services/Service';
import { formatMoney } from '../../utils/Utils';
import { ItemPickForm } from './ItemPickForm';
import { ItemPickModal } from './ItemPickModal';
import { ProductItem } from './ProductItem';
import { Player } from 'video-react';
import 'video-react/dist/video-react.css';
const NEW_CART_ITEM = {
    id: '',
    name: '',
    price: 0,
    unit: '',
    quantity: 0,
    imgs: [],
};

const { Option } = Select;
const BuyForm = () => {
    let { id, shopId } = useParams();
    const history = useHistory();
    const oldInfo = JSON.parse(localStorage.getItem('userInfo'));
    const currentUserInfo = JSON.parse(localStorage.getItem('userData'));
    const oldCart = JSON.parse(localStorage.getItem('cart'));
    const [loading, setLoading] = useState(false);
    const [apartmentInfo, setApartmentInfo] = useState(null);
    const [userInfo, setUserInfo] = useState(
        (currentUserInfo && { name: currentUserInfo.username, phone: currentUserInfo.contactNo, room: '', message: '' }) ||
            oldInfo || {
                name: '',
                phone: '',
                room: '',
                message: '',
            }
    );
    const [comment, setComment] = useState('');
    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState(oldCart || []);
    const [cartPickVisible, setCartPickVisible] = useState(false);
    const [selectedCartId, setSelectedCartId] = useState(-1);
    const [disableProductsByManager, setDisableProductsByManager] = useState([]);
    const [selectedPro, setSelectedPro] = useState(null);
    const [selectedVariant, setSelectedVariant] = useState(null);
    const [shopList, setShopList] = useState([]);
    const [variants, setVariants] = useState([]);
    const [isShopFollowed, setShopFollowed] = useState(false);

    useEffect(() => {
        if (apartmentInfo && apartmentInfo.managerId) {
            BoService.getDisablePostOfManager(apartmentInfo.managerId, (res) => {
                setDisableProductsByManager((res && res.list) || []);
            });
        }
    }, [apartmentInfo]);

    useEffect(() => {
        if (disableProductsByManager && products) {
            const list = products.filter((pro) => {
                return !disableProductsByManager.some((proId) => pro.id === proId);
            });
            setProducts(list);
        }
    }, [disableProductsByManager]);

    useEffect(() => {
        if (!selectedPro) {
            return;
        }
        if (shopId) {
            BoService.getShopProductVariantsById(selectedPro.id, (res) => {
                setVariants(res || []);
            });
            return;
        }
        BoService.getPostVariantsById(selectedPro.id, (res) => {
            setVariants(res || []);
        });
    }, [selectedPro]);

    useEffect(() => {
        if (id) {
            setLoading(true);
            BoService.getApartmentInfo(id, (data) => {
                setApartmentInfo(data);
                setLoading(false);
            });
        }
        if (shopId) {
            setLoading(true);
            BoService.getShopInfo(shopId, (data) => {
                setApartmentInfo(data);
                setLoading(false);
            });
        }

        BoService.getShopList((res) => setShopList(res));
        const followedShop = (currentUserInfo.followShops || []).find((shop) => shop.id === shopId);
        setShopFollowed(!!followedShop);
    }, []);

    useEffect(() => {
        if (shopId) {
            BoService.getShopProduct((data) => {
                const list = (data || []).filter((item) => item.enabled && item.owner === shopId);
                console.log(list, shopId);
                setProducts(list);
            });
            return;
        }
        BoService.getPostList((data) => {
            const list = (data || []).filter((item) => item.enabled);
            setProducts(list);
        });
    }, []);

    const followShop = () => {
        if (!currentUserInfo) {
            return;
        }
        BoService.updateShopAccount({ ...currentUserInfo, followShops: [...(currentUserInfo.followShops || []), apartmentInfo] });
        setShopFollowed(true);
    };

    const unfollowShop = () => {
        if (!currentUserInfo) {
            return;
        }
        const newFollowShops = [...(currentUserInfo.followShops || [])].filter((shop) => shop.id !== shopId);
        BoService.updateShopAccount({ ...currentUserInfo, followShops: newFollowShops });
        setShopFollowed(false);
    };

    const onChangeValueUser = (field, value) => {
        const newInfo = { ...userInfo };
        newInfo[field] = value;
        setUserInfo(newInfo);
    };

    const onChangeCart = (productId, products, field, value) => {
        const newCart = [...cart];
        let foundCart = null;
        const index = newCart.findIndex((cart) => cart.id === productId);
        if (index >= 0) {
            if (field === 'quantity' && value == 0) {
                newCart.splice(index, 1);
            } else {
                foundCart = { ...newCart[index] };
                foundCart[field] = value;
                newCart[index] = foundCart;
            }
        } else {
            const pro = products.find((item) => item.id === productId);
            newCart.push({ ...pro, [field]: value });
        }
        console.log(newCart);
        setCart(newCart);
    };

    const onRemoveCartItem = (cartIndex) => {
        let newCart = [...cart];
        newCart.splice(cartIndex, 1);
        setCart(newCart);
    };

    const onAddCartItem = () => {
        let newCart = [...cart];
        newCart.push({ ...NEW_CART_ITEM });
        setCart(newCart);
    };

    const onCheckout = () => {
        if (!userInfo.name || !userInfo.phone || !userInfo.room) {
            message.error('Vui lòng đièn đủ thông tin');
            return;
        }
        let canProceed = true;
        cart.map((item) => {
            console.log(cart);
            if (!item.id) {
                message.error('Còn sản phẩm chưa điền thông tin');
                canProceed = false;
            }
            if (item.quantity === 0) {
                message.error('Còn sản phẩm chưa nhập số lượng');
                canProceed = false;
            }
        });
        if (!canProceed) {
            return;
        }
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        localStorage.setItem('cart', JSON.stringify(cart));
        localStorage.setItem('apartmentInfo', JSON.stringify(apartmentInfo));
        if (shopId) {
            history.push('/checkout/shop/' + shopId);
        } else {
            history.push('/checkout');
        }
    };

    if (loading) {
        return (
            <div className="flex-1">
                <Spin />
            </div>
        );
    }

    if (!apartmentInfo) {
        return (
            <div className="flex-1">
                <Alert
                    message="Không tìm thấy thông tin chung cư hợp lệ, nếu chung cư bạn sống chưa được đăng kí, vui lòng liên hệ chúng tôi"
                    type="error"
                />
            </div>
        );
    }

    const renderShopInfo = () => {
        return (
            <>
                <Card
                    className="w-full md:w-2/3"
                    style={{ height: 'fit-content' }}
                    bodyStyle={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
                >
                    <div className="text-xl text-center font-bold">{apartmentInfo.name}</div>
                    <div className="text-center">Địa chỉ: {apartmentInfo.address}</div>
                    <div>
                        {!isShopFollowed ? (
                            <Button type="primary" onClick={followShop}>
                                Theo dõi shop
                            </Button>
                        ) : (
                            <Button type="primary" onClick={unfollowShop}>
                                Bỏ theo dõi
                            </Button>
                        )}
                        <a href={`/chat/${shopId}`}>
                            <Button type="primary ml-3">Nhắn tin</Button>
                        </a>
                    </div>
                </Card>
                <div className="w-full md:w-2/3 flex justify-center items-center relative my-2" style={{ height: '150px' }}>
                    <img src={apartmentInfo.imgs && apartmentInfo.imgs[0].url} alt="st" className="w-full h-full object-cover" />
                    <span
                        className="absolute font-bold text-xl p-2 text-center"
                        style={{ borderRadius: 20, backgroundColor: '#ffffffbf' }}
                    >
                        Chào mừng quí khách đến với {apartmentInfo.name}
                    </span>
                </div>
            </>
        );
    };

    const renderProducts = (products, onClick) => {
        return (
            <Card className="w-full md:w-2/3 flex justify-center" style={{ height: 'fit-content' }} bodyStyle={{ width: '100%' }}>
                <ItemPickForm
                    products={products}
                    carts={cart}
                    onChangeCart={(productId, field, value) => onChangeCart(productId, products, field, value)}
                    onSelect={onClick}
                />
            </Card>
        );
    };
    const submitComment = () => {
        BoService.updateShopComments(selectedPro, [...(selectedPro.comments || []), comment], () => {
            setSelectedPro((prev) => ({ ...prev, comments: [...(selectedPro.comments || []), comment] }));
        });
    };

    const renderShopList = () => {
        let list = [...shopList];
        console.log(apartmentInfo);
        if (apartmentInfo.provine) {
            list = list.filter((shop) => shop.info && shop.info.provine && shop.info.provine.code == apartmentInfo.provine.code);
        }
        if (apartmentInfo.district) {
            list = list.filter(
                (shop) => shop.info && shop.info.district && shop.info.district.code == apartmentInfo.district.code
            );
        }
        if (apartmentInfo.ward) {
            list = list.filter((shop) => shop.info && shop.info.ward && shop.info.ward.code == apartmentInfo.ward.code);
        }
        return (
            <Card className="w-full flex flex-col mt-3">
                <span className="font-bold text-lg">Danh sách shop</span>
                <div className="grid grid-cols-2 md:grid-cols-5 gap-3">
                    {list.map((shop) => {
                        return (
                            <div>
                                <img
                                    src={(shop.info.imgs && shop.info.imgs[0].url) || NO_IMAGE_URL}
                                    alt="name"
                                    style={{ height: '100px' }}
                                    className="w-full object-cover"
                                />
                                <a href={`/shop/${shop.id}`}>
                                    <div className="flex flex-col">
                                        <span className="text-base font-bold text-blue-500">{shop.info.name}</span>
                                        <span className="text-xs primary">{shop.info.address}</span>
                                    </div>
                                </a>
                            </div>
                        );
                    })}
                </div>
            </Card>
        );
    };

    console.log(selectedPro);
    return (
        <div className="flex justify-center flex-1 w-full p-3" style={{ backgroundColor: 'rgb(229, 231, 235)' }}>
            <ItemPickModal
                visible={cartPickVisible}
                products={products}
                onSelect={(product) => {
                    onChangeCart(selectedCartId, 'id', product.id);
                    setCartPickVisible(false);
                }}
                carts={cart}
                onClose={() => setCartPickVisible(false)}
            />
            <div className="flex items-center w-full flex-col">
                {renderShopInfo()}
                <Card className="w-full md:w-2/3" style={{ height: 'fit-content' }}>
                    <div>
                        <span className="font-bold">{apartmentInfo.name}</span>
                    </div>
                    <div>
                        <div className="w-full md:w-1/3 font-bold">Thông tin khách hàng</div>
                        <Input value={userInfo.name} onChange={(e) => onChangeValueUser('name', e.target.value)}></Input>
                    </div>
                    <div className="mt-3">
                        <div className="w-full md:w-1/3 font-bold">Số điện thoại</div>
                        <Input value={userInfo.phone} onChange={(e) => onChangeValueUser('phone', e.target.value)}></Input>
                    </div>

                    <div className="mt-3">
                        <div className="w-full md:w-1/3 font-bold">
                            {apartmentInfo.type === 'apartment' ? 'Phòng (VD: 102)' : 'Địa chỉ'}
                        </div>
                        <Input value={userInfo.room} onChange={(e) => onChangeValueUser('room', e.target.value)}></Input>
                    </div>
                    {/* <div className="mt-3">
                        <div className="w-full md:w-1/3 font-bold">Tin nhắn</div>
                        <Input value={userInfo.message} onChange={(e) => onChangeValueUser('message', e.target.value)}></Input>
                    </div> */}
                </Card>
                {renderProducts(products, setSelectedPro)}

                <div className="flex w-full md:w-2/3 mt-3 flex-col md:flex-row md:justify-between fixed bottom-0 md:relative">
                    {/* <Button type="dashed" onClick={onAddCartItem}>
                        + Thêm sản phẩm
                    </Button> */}
                    <Button type="primary" className="mt-3 md:mt-0" onClick={onCheckout}>
                        Kiểm tra đơn hàng
                    </Button>
                </div>
                {selectedPro && (
                    <Tabs defaultActiveKey="variants" className="flex w-full md:w-2/3 mt-3  md:justify-between">
                        <Tabs.TabPane tab="Hình ảnh" key="1">
                            <div className="flex flex-row w-full" style={{ overflow: 'auto' }}>
                                {(selectedPro.imgs || []).map((img) => {
                                    return (
                                        <div className="mx-2">
                                            <Image width={200} height={150} src={img.url} />{' '}
                                        </div>
                                    );
                                })}
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Biến thể" key="variants">
                            {variants.length > 0 && renderProducts(variants, setSelectedVariant)}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Video" key="2">
                            {selectedPro.videos && selectedPro.videos[0] && (
                                <Player playsInline src={selectedPro.videos[0].url} />
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Thông tin sản phẩm" key="3">
                            <div style={{ whiteSpace: 'pre-wrap' }}>{selectedPro.desc}</div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Bình luận" key="4">
                            <div className="flex flex-col">
                                {(selectedPro.comments || []).map((comment) => {
                                    return (
                                        <div className="my-2">
                                            <Avatar size={35} icon={<UserOutlined />} />
                                            <span className="ml-2">{comment}</span>
                                        </div>
                                    );
                                })}
                                <div className="w-full">
                                    <Input.Group className="flex">
                                        <Input value={comment} onChange={(e) => setComment(e.target.value)} />
                                        <Button type="primary" onClick={submitComment}>
                                            Gửi
                                        </Button>
                                    </Input.Group>
                                </div>
                            </div>
                        </Tabs.TabPane>
                    </Tabs>
                )}
                <div className="flex w-full md:w-2/3 mt-3 flex-col md:flex-row md:justify-between">{renderShopList()}</div>
            </div>
        </div>
    );
};
export default BuyForm;
