import { Button, Card, Input, message } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { createGroup, createGroupmanager, getGroup, getGroupManager, updateGroup, updateGroupManager } from '../../services/Service';

const NEW_GROUP_MANAGER = {
    username: '',
    password: '',
    role: 'group-manager',
    contactName: '',
    contactNo: '',
};

export const GroupManagerForm = () => {
    let { id } = useParams();
    const [groupManager, setGroupManager] = useState(NEW_GROUP_MANAGER);
    const history = useHistory();

    useEffect(() => {
        if (!id) {
            return;
        }
        getGroupManager(id, (res) => {
            console.log(res);
            setGroupManager(res);
        });
    }, [id]);

    const onChangeValue = (field, value) => {
        const newProduct = { ...groupManager };
        newProduct[field] = value;
        setGroupManager(newProduct);
    };

    const onSave = () => {
        if (!groupManager.username || !groupManager.password) {
            message.error('Vui lòng nhập đầy đủ thông tin');
            return;
        }
        if (id) {
            updateGroupManager({ ...groupManager, enabled: true });
        } else {
            createGroupmanager({ ...groupManager, enabled: true });
        }

        message.info('Tạo tài khoản thành công');
        history.push('/bo/group-manager');
    };

    return (
        <div>
            <Card className="w-full md:w-1/3 p-1 mt-2">
                <span className="text-lg font-bold">{id ? 'Cập nhật' : 'Thêm quản lý nhóm'}</span>
                <div className="flex  mt-5">
                    <div className="w-1/3">Tên đăng nhập</div>
                    <Input value={groupManager.username} onChange={(e) => onChangeValue('username', e.target.value)}></Input>
                </div>
                <div className="flex  mt-5">
                    <div className="w-1/3">Password</div>
                    <Input value={groupManager.password} onChange={(e) => onChangeValue('password', e.target.value)}></Input>
                </div>
                <div className="flex  mt-5">
                    <div className="w-1/3">Tên người liên hệ</div>
                    <Input
                        value={groupManager.contactName}
                        onChange={(e) => onChangeValue('contactName', e.target.value)}
                    ></Input>
                </div>
                <div className="flex  mt-5">
                    <div className="w-1/3">Số điện thoại liên hệ</div>
                    <Input value={groupManager.contactNo} onChange={(e) => onChangeValue('contactNo', e.target.value)}></Input>
                </div>
            </Card>
            <div className="flex flex-row mt-2">
                <Button type="primary" className="mr-5" onClick={onSave}>
                    Lưu
                </Button>
                <Button type="dashed" href="/bo/group-manager">
                    Hủy
                </Button>
            </div>
        </div>
    );
};
