import 'react-chat-elements/dist/main.css';
import { ChatItem, MessageBox, MessageList } from 'react-chat-elements';
import { Button, Input } from 'antd';
import { useEffect, useState } from 'react';
import { UserService } from '../services/Service';
import { ChatList } from './ChatList';
import { useParams } from 'react-router-dom';
import Search from 'antd/lib/input/Search';
import './index.css';
export const ChatPage = ({}) => {
    let { receiveId } = useParams();
    const userId = localStorage.getItem('userId');
    const username = localStorage.getItem('username');

    const [chatData, setChatData] = useState([]);
    const [curentReceiver, setCurrenReceiver] = useState(receiveId);
    const [currentChat, setCurrentChat] = useState([]);
    const [chatText, setChatText] = useState('');
    useEffect(() => {
        const chatListener = UserService.getChatOfUser();
        chatListener.on('value', (snapshot) => {
            let data = [];
            snapshot.forEach((childSnapshot) => {
                const childKey = childSnapshot.key;
                const childData = childSnapshot.val();
                const list = (childData.list || []).map((item) => {
                    return {
                        ...item,
                        text: item.content,
                        position: item.sender === userId ? 'right' : 'left',
                        type: 'text',
                        date: new Date(item.date),
                    };
                });
                data.push({
                    id: childKey,
                    ...childData,
                    list,
                });
            });

            let currentChatData = data.find((item) => item.id === curentReceiver);
            console.log(currentChatData, curentReceiver);
            // If this is new chat
            if (!currentChatData && receiveId) {
                currentChatData = {
                    id: receiveId,
                    list: [],
                };
                data.push(currentChatData);
            }
            setCurrentChat((currentChatData && currentChatData.list) || []);
            setChatData(data);
        });

        return () => {
            chatListener.off();
        };
    }, [curentReceiver]);

    useEffect(() => {
        const currentChatData = chatData.find((item) => item.id === curentReceiver);
        setCurrentChat((currentChatData && currentChatData.list) || []);
    }, [curentReceiver]);

    const onSubmitChat = () => {
        if (!chatText) {
            return;
        }
        const newChatList = [...currentChat];
        const date = new Date();
        newChatList.push({
            content: chatText,
            date: date.toUTCString(),
            sender: userId,
            senderName: username,
        });
        console.log(curentReceiver, newChatList);
        newChatList.forEach((item) => {
            delete item['position'];
            delete item['text'];
        });
        UserService.submitChat(curentReceiver, newChatList);
        setChatText('');
    };
    console.log('current', curentReceiver);
    return (
        <div className="flex h-full">
            <div className="w-1/4">
                <ChatList
                    chatData={chatData}
                    onClickChat={(receiver) => setCurrenReceiver(receiver)}
                    currentReceiver={curentReceiver}
                />
            </div>
            <div className="w-3/4 h-full flex flex-col flex-1 pb-10 pr-10">
                <MessageList className="message-list flex-1" lockable={true} toBottomHeight={'100%'} dataSource={currentChat} />
                <Search
                    placeholder="Nhập nội dung"
                    allowClear
                    enterButton="Gửi"
                    size="large"
                    value={chatText}
                    onChange={(e) => setChatText(e.target.value)}
                    onSearch={onSubmitChat}
                />
            </div>
        </div>
    );
};
