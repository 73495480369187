import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { BoService } from '../../services/Service';
import { ShopItem } from './ShopItem';

export const ShopList = () => {
    const [shopList, setShopList] = useState([]);
    useEffect(() => {
        BoService.getShopList((res) => setShopList(res));
    }, []);
    return (
        <div className="flex p-2 flex-col">
            <Button type="primary" className="mt-2 w-full md:w-2/12 lg:w-2/12 xl:w-1/12" href="/bo/shops/new">
                Thêm shop
            </Button>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-3 mt-2">
                {shopList.map((shop) => {
                    return <ShopItem key={shop.id} shop={shop} />;
                })}
            </div>
        </div>
    );
};
