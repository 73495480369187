import { Button, Input, message, Tabs, Divider } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ImageUploader } from '../../components/ImageUploader';
import { BoService } from '../../services/Service';
const { TabPane } = Tabs;

const EMPTY_CONTENT = {
    content: '',
    imageSub: '',
    imgList: [],
};

const NewPost = ({}) => {
    let { id } = useParams();
    const [post, setPost] = useState({
        thought: '',
        title: '',
        url: '',
        introduction: '',
        ref: '',
        thumbnail: [],
        author: '',
    });
    const [contents, setContents] = useState([EMPTY_CONTENT]);

    useEffect(() => {
        if (!id) {
            return;
        }
        BoService.getPostById(id, (data) => {
            if (data) {
                setPost(data);
                const contentData = data.contents || [];
                setContents(contentData);
            }
        });
    }, [id]);

    const onChangeValue = (field, value) => {
        const newPost = { ...post };
        newPost[field] = value;
        setPost(newPost);
    };

    const onChangeContent = (idx, field, value) => {
        const newContents = [...contents];
        const newContent = { ...newContents[idx] };
        newContent[field] = value;
        newContents[idx] = newContent;
        setContents(newContents);
    };

    const onFinishUploadImage = (index, name, url) => {
        const newImg = {
            name,
            uid: name,
            url,
        };
        const imgList = [...contents[index].imgList];
        const newList = [...imgList, newImg];
        onChangeContent(index, 'imgList', newList);
    };
    const onRemoveImage = (index, imageId) => {
        const imgList = [...contents[index].imgList];
        const newImgList = imgList.filter((img) => img.uid !== imageId);
        onChangeContent(index, 'imgList', newImgList);
    };

    const onChangeThumbnail = (name, url) => {
        const newImg = {
            name,
            uid: name,
            url,
        };
        onChangeValue('thumbnail', [newImg]);
    };

    const onRemoveThumbnail = () => {
        onChangeValue('thumbnail', []);
    };

    const onSubmit = () => {
        if (id) {
            BoService.updatePost({ ...post, contents });
        } else {
            BoService.createPost({ ...post, contents });
        }

        message.info('Đăng bài thành công');
        setPost({
            thought: '',
            title: '',
            url: '',
            introduction: '',
            ref: '',
            imageSub: '',
            author: '',
            thumbnail: [],
        });
        setContents([EMPTY_CONTENT]);
    };

    const addContent = () => {
        const newContents = [...contents, EMPTY_CONTENT];
        setContents(newContents);
    };

    return (
        <div>
            <div className="flex w-2/3">
                <div className="w-1/3">Bạn đang nghĩ gì</div>
                <Input.TextArea value={post.thought} onChange={(e) => onChangeValue('thought', e.target.value)}></Input.TextArea>
            </div>
            <div className="flex w-2/3 mt-5">
                <div className="w-1/3">Tên bài viết</div>
                <Input value={post.title} onChange={(e) => onChangeValue('title', e.target.value)}></Input>
            </div>
            <div className="flex w-2/3 mt-5">
                <div className="w-1/3">Ảnh đại diện</div>
                <ImageUploader
                    onFinishUploadImage={(name, url) => onChangeThumbnail(name, url)}
                    imgList={post.thumbnail}
                    maxFiles={1}
                    removeImage={(imageId) => onRemoveThumbnail(imageId)}
                />
            </div>
            <div className="flex w-2/3 mt-5">
                <div className="w-1/3">Đương link gốc</div>
                <Input value={post.url} onChange={(e) => onChangeValue('url', e.target.value)}></Input>
            </div>
            <div className="flex w-2/3 mt-5">
                <div className="w-1/3">Giới thiệu</div>
                <Input.TextArea
                    value={post.introduction}
                    onChange={(e) => onChangeValue('introduction', e.target.value)}
                ></Input.TextArea>
            </div>
            <div className="flex w-2/3 mt-5">
                <div className="w-1/3">Nguồn web</div>
                <Input value={post.ref} onChange={(e) => onChangeValue('ref', e.target.value)}></Input>
            </div>
            {contents.map((content, index) => {
                return (
                    <>
                        <div className="flex w-2/3 mt-5">
                            <div className="w-1/3">Nội dung</div>
                            <Input.TextArea
                                value={content.content}
                                onChange={(e) => onChangeContent(index, 'content', e.target.value)}
                            ></Input.TextArea>
                        </div>
                        <ImageUploader
                            onFinishUploadImage={(name, url) => onFinishUploadImage(index, name, url)}
                            imgList={content.imgList}
                            maxFiles={50}
                            removeImage={(imageId) => onRemoveImage(index, imageId)}
                        />
                        <div className="flex w-2/3 mt-5">
                            <div className="w-1/3">Phụ đề hình</div>
                            <Input.TextArea
                                value={content.imageSub}
                                onChange={(e) => onChangeContent(index, 'imageSub', e.target.value)}
                            ></Input.TextArea>
                        </div>
                        <Divider></Divider>
                    </>
                );
            })}
            <div className="flex w-2/3 mt-5 justify-end">
                <Button type="link" onClick={addContent}>
                    Thêm nội dung
                </Button>
            </div>

            <div className="flex w-2/3 mt-5">
                <div className="w-1/3">Biên tập viên</div>
                <Input value={post.author} onChange={(e) => onChangeValue('author', e.target.value)}></Input>
            </div>
            <Button type="primary" className="mt-5" onClick={onSubmit}>
                Đăng bài
            </Button>
        </div>
    );
};

const SocialPost = ({}) => {
    return (
        <div>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Thông tin và dữ liệu" key="1">
                    <NewPost />
                </TabPane>
                <TabPane tab="Dự án mới" key="2">
                    Content of Tab Pane 2
                </TabPane>
                <TabPane tab="Cổ phiếu" key="3">
                    Content of Tab Pane 3
                </TabPane>
                <TabPane tab="Dự án mở rộng" key="4">
                    Content of Tab Pane 3
                </TabPane>
            </Tabs>
        </div>
    );
};

export default SocialPost;
