export const FollowShopList = () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    console.log(userData);
    return (
        <div className="grid gap-2 grid-cols-2 md:grid-cols-5 mt-3 lg:grid-cols-7">
            {((userData && userData.followShops) || []).map((shop) => {
                if (!shop) {
                    return;
                }
                return (
                    <a href={`/shop/${shop.id}`}>
                        <div>
                            <img
                                // eslint-disable-next-line no-undef
                                src={(shop.imgs && shop.imgs[0].url) || NO_IMAGE_URL}
                                alt="name"
                                style={{ height: '100px' }}
                                className="w-full object-cover"
                            />

                            <div className="flex flex-col">
                                <span className="text-base font-bold text-blue-500">{shop.name}</span>
                                <span className="text-xs primary">{shop.address}</span>
                            </div>
                        </div>
                    </a>
                );
            })}
        </div>
    );
};
