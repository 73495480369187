import { Button, Card, Input, message, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { createGroup, getGroup, getGroupManagerList, updateGroup, updateGroupManager } from '../../services/Service';

const NEW_GROUP = {
    name: '',
    type: 'group',
    address: '',
    contactName: '',
    contactNo: '',
    managerId: '',
};

export const GroupForm = () => {
    let { id } = useParams();
    const [group, setGroup] = useState(NEW_GROUP);
    const history = useHistory();
    const [groupManagers, setGroupManagers] = useState([]);

    useEffect(() => {
        getGroupManagerList((res) => setGroupManagers(res));
    }, []);

    useEffect(() => {
        if (!id) {
            return;
        }
        getGroup(id, (res) => {
            console.log(res);
            setGroup(res);
        });
    }, [id]);

    const onChangeValue = (field, value) => {
        const newProduct = { ...group };
        newProduct[field] = value;
        setGroup(newProduct);
    };

    const onSave = () => {
        if (!group.name || !group.address) {
            message.error('Vui lòng nhập đầy đủ thông tin');
            return;
        }

        const afterSaved = (res) => {
            if (group.managerId) {
                const manager = groupManagers.find((m) => m.id === group.managerId);
                updateGroupManager({ ...manager, apartmentId: id || res.id });
            }
        };
        if (id) {
            updateGroup({ ...group, enabled: true }, afterSaved);
        } else {
            createGroup({ ...group, enabled: true }, afterSaved);
        }

        message.info('Đăng sản phẩm thành công');
        history.push('/bo/groups');
    };

    return (
        <div>
            <Card className="w-full md:w-1/3 p-1 mt-2">
                <span className="text-lg font-bold">{id ? 'Cập nhật' : 'Thêm nhóm'}</span>
                <div className="flex  mt-5">
                    <div className="w-1/3">Tên nhóm</div>
                    <Input value={group.name} onChange={(e) => onChangeValue('name', e.target.value)}></Input>
                </div>
                <div className="flex  mt-5">
                    <div className="w-1/3">Địa chỉ</div>
                    <Input value={group.address} onChange={(e) => onChangeValue('address', e.target.value)}></Input>
                </div>
                <div className="flex  mt-5">
                    <div className="w-1/3">Người quản lý</div>
                    <Select
                        style={{ width: 120 }}
                        value={group.managerId}
                        onChange={(id) => {
                            onChangeValue('managerId', id);
                        }}
                    >
                        {groupManagers.map((manager) => {
                            return <Select.Option value={manager.id}>{manager.contactName}</Select.Option>;
                        })}
                    </Select>
                </div>
            </Card>
            <div className="flex flex-row mt-2">
                <Button type="primary" className="mr-5" onClick={onSave}>
                    Lưu
                </Button>
                <Button type="dashed" href="/bo/groups">
                    Hủy
                </Button>
            </div>
        </div>
    );
};
