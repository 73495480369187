import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, message, Popconfirm } from 'antd';

export const GroupItem = ({ group, onEdit }) => {
    const copyUrl = () => {
        const body = document.querySelector('body');
        const paragraph = document.querySelector('p');
        const area = document.createElement('textarea');
        body.appendChild(area);

        area.value = `https://muaban.app/${group.id}`;
        area.select();
        document.execCommand('copy');
        body.removeChild(area);
        message.success('Đã copy');
    };
    return (
        <Card hoverable>
            <div className="flex flex-col">
                <span>Tên: {group.name}</span>
                <span>Địa chỉ: {group.address}</span>
                <span>Loại: {group.type === 'group' ? 'Nhóm mua' : 'Chung cư'}</span>

                <div className="flex w-full justify-between">
                    <a href={`/bo/groups/edit/${group.id}`} style={{ height: 20, fontSize: 20 }} className="mr-3">
                        <EditOutlined size={30} />
                    </a>
                    <div style={{ height: 20, fontSize: 20 }} className="mr-3">
                        <CopyOutlined size={30} onClick={copyUrl} />
                    </div>
                </div>
            </div>
        </Card>
    );
};
