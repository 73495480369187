import { Button, Card, Input, message, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { ROLE } from '../../constant';
import {
    BoService,
    createGroup,
    createGroupmanager,
    getAllProvider,
    getGroup,
    getGroupManager,
    updateGroup,
    updateGroupManager,
} from '../../services/Service';

const NEW_SHOP = {
    username: '',
    password: '',
    code: '',
    role: ROLE.SHOP,
    contactName: '',
    contactNo: '',
    name: '',
    address: '',
    provine: null,
};

export const ShopForm = () => {
    let { id } = useParams();
    const [shop, setShop] = useState(NEW_SHOP);
    const [provines, setProvines] = useState([]);
    const history = useHistory();

    useEffect(() => {
        getAllProvider().then((res) => setProvines(res));
    }, []);

    useEffect(() => {
        if (!id) {
            return;
        }
        BoService.getShopAccount(id, (res) => {
            console.log(res);
            setShop(res);
        });
    }, [id]);

    const onChangeValue = (field, value) => {
        const newProduct = { ...shop };
        newProduct[field] = value;
        if (field === 'provine') {
            const provine = (provines || []).find((pr) => pr.code === value);
            newProduct[field] = provine;
        }

        setShop(newProduct);
    };

    const onSave = () => {
        if (!shop.username || !shop.password) {
            message.error('Vui lòng nhập đầy đủ thông tin');
            return;
        }
        if (id) {
            BoService.updateShopAccount({ ...shop, enabled: true });
        } else {
            BoService.createShop({ ...shop, enabled: true });
        }

        message.info('Tạo tài khoản thành công');
        history.push('/bo/shops');
    };
    console.log('gg', provines);
    return (
        <div>
            <Card className="w-full md:w-1/3 p-1 mt-2">
                <span className="text-lg font-bold">{id ? 'Cập nhật' : 'Thêm quản lý nhóm'}</span>
                <div className="flex  mt-5">
                    <div className="w-1/3">Tên đăng nhập</div>
                    <Input value={shop.username} onChange={(e) => onChangeValue('username', e.target.value)}></Input>
                </div>
                <div className="flex  mt-5">
                    <div className="w-1/3">Password</div>
                    <Input value={shop.password} onChange={(e) => onChangeValue('password', e.target.value)}></Input>
                </div>
                <div className="flex  mt-5">
                    <div className="w-1/3">Tên người liên hệ</div>
                    <Input value={shop.contactName} onChange={(e) => onChangeValue('contactName', e.target.value)}></Input>
                </div>
                <div className="flex  mt-5">
                    <div className="w-1/3">Số điện thoại liên hệ</div>
                    <Input value={shop.contactNo} onChange={(e) => onChangeValue('contactNo', e.target.value)}></Input>
                </div>
                <div className="flex  mt-5">
                    <div className="w-1/3">Tên shop</div>
                    <Input value={shop.name} onChange={(e) => onChangeValue('name', e.target.value)}></Input>
                </div>
                <div className="flex  mt-5">
                    <div className="w-1/3">Mã shop</div>
                    <Input value={shop.code} onChange={(e) => onChangeValue('code', e.target.value)}></Input>
                </div>
                <div className="flex  mt-5">
                    <div className="w-1/3">Địa chỉ</div>
                    <Input value={shop.address} onChange={(e) => onChangeValue('address', e.target.value)}></Input>
                </div>
                <Select
                    className="w-full mt-2"
                    value={shop.provine && shop.provine.code}
                    onChange={(value) => onChangeValue('provine', value)}
                    placeholder="Chọn trạng thái"
                >
                    {provines.map((provine, index) => {
                        return <Select.Option key={provine.code}>{provine.name}</Select.Option>;
                    })}
                </Select>
            </Card>
            <div className="flex flex-row mt-2">
                <Button type="primary" className="mr-5" onClick={onSave}>
                    Lưu
                </Button>
                <Button type="dashed" href="/bo/group-manager">
                    Hủy
                </Button>
            </div>
        </div>
    );
};
