import logo from './logo.svg';
import './App.css';
import React, { useEffect, useReducer, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import Login from './Login';
import { Spin } from 'antd';
import BoPage from './bo-page/BoPage';
import { BoHeader } from './components/BoHeader';
import BuyForm from './components/buy-form/BuyFormV2';
import { ProductForm } from './components/product-manage/ProductForm';
import { CheckoutPage } from './components/buy-form/Checkout';
import { Footer } from './components/Footer';
import { Home } from './home/Home';
import { ChatPage } from './chat-page/ChatPage';
export const AppContext = React.createContext();
const inititalContext = {};

const contextReducer = (state, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

const App = () => {
    const [context, dispatch] = useReducer(contextReducer, inititalContext);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // BoService.getInvestProductList(onGetInvestListSuccess);
    }, []);

    return (
        <AppContext.Provider value={{ context, dispatch }}>
            <Router>
                <div className="h-full flex flex-1 flex-col">
                    {isLoading && (
                        <div className="loading">
                            <Spin />
                        </div>
                    )}
                    <Switch>
                        <Route path="/login">
                            <Login />
                        </Route>
                        <Route path="/bo">
                            <BoHeader />
                            <BoPage />
                        </Route>
                        <Route path="/checkout/shop/:shopId">
                            <BoHeader />
                            <CheckoutPage />
                            <Footer />
                        </Route>
                        <Route path="/checkout">
                            <BoHeader />
                            <CheckoutPage />
                            <Footer />
                        </Route>
                        <Route path="/chat/:receiveId">
                            <div className="h-full flex flex-col">
                                <BoHeader />
                                <ChatPage />
                            </div>
                        </Route>
                        <Route path="/chat">
                            <div className="h-full flex flex-col">
                                <BoHeader />
                                <ChatPage />
                            </div>
                        </Route>
                        <Route path="/shop/:shopId">
                            <BoHeader />
                            <BuyForm />
                            <Footer />
                        </Route>
                        <Route path="/:id">
                            <BoHeader />
                            <BuyForm />
                            <Footer />
                        </Route>

                        <Route path="/">
                            <BoHeader />
                            <Home />
                            <Footer />
                        </Route>
                    </Switch>
                </div>
            </Router>
        </AppContext.Provider>
    );
};

export default App;
