import { Button, Card, Input, InputNumber, message } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ROLE } from '../../constant';
import { BoService } from '../../services/Service';
import { ImageUploader } from '../ImageUploader';
import { VideoUploader } from '../VideoUploader';

const NEW_PRODUCT = {
    name: '',
    code: '',
    unit: '',
    price: 0,
    imgs: [],
    videos: [],
    desc: '',
};
export const ProductForm = () => {
    let { id } = useParams();
    const role = localStorage.getItem('role');
    const [product, setProduct] = useState({ ...NEW_PRODUCT });
    const [variants, setVariants] = useState([]);

    useEffect(() => {
        if (!id) {
            return;
        }
        if (role === ROLE.SHOP) {
            BoService.getShopProductById(id, (data) => {
                if (data) {
                    setProduct(data);
                }
            });
            BoService.getShopProductVariantsById(id, (data) => {
                console.log(data);
                if (data) {
                    setVariants(data);
                }
            });
            return;
        }
        BoService.getPostById(id, (data) => {
            if (data) {
                setProduct(data);
            }
        });
        BoService.getPostVariantsById(id, (data) => {
            if (data) {
                setVariants(data);
            }
        });
    }, [id]);

    const onChangeValue = (field, value) => {
        const newProduct = { ...product };
        newProduct[field] = value;
        setProduct(newProduct);
    };

    const onChangeVariantValue = (index, field, value) => {
        const newVariant = { ...variants[index] };
        newVariant[field] = value;
        const newVariants = [...variants];
        newVariants[index] = newVariant;
        setVariants(newVariants);
    };

    const createVariants = (product) => {
        if (variants.length === 0) {
            return;
        }
        // eslint-disable-next-line array-callback-return
        variants.map((variant) => {
            if (role === ROLE.SHOP) {
                BoService.createShopProductVariant({ ...product }, variant);
            } else {
                BoService.createPostVariant({ ...product }, variant);
            }
        });
    };

    const updateVariants = (product) => {
        if (variants.length === 0) {
            return;
        }
        console.log('update variant', product, variants);
        // eslint-disable-next-line array-callback-return
        variants.map((variant) => {
            if (role === ROLE.SHOP) {
                if (variant.id) {
                    BoService.updateShopProductVariant({ ...product }, variant);
                    return;
                }
                BoService.createShopProductVariant({ ...product }, variant);
            } else {
                if (variant.id) {
                    BoService.updatePostVariant({ ...product }, variant);
                    return;
                }
                BoService.createPostVariant({ ...product }, variant);
            }
        });
    };

    const onSave = () => {
        if (!product.name || !product.unit || !product.price) {
            message.error('Vui lòng nhập đầy đủ thông tin');
            return;
        }
        if (id) {
            if (role === ROLE.SHOP) {
                BoService.updateShopProduct({ ...product, enabled: true }, (res) => {
                    updateVariants(res);
                    setVariants([]);
                });
            } else {
                BoService.updatePost({ ...product, enabled: true }, (res) => {
                    updateVariants(res);
                    setVariants([]);
                });
            }
        } else {
            if (role === ROLE.SHOP) {
                BoService.createShopProduct({ ...product, enabled: true }, (res) => {
                    createVariants(res);
                    setVariants([]);
                });
            } else {
                BoService.createPost({ ...product, enabled: true }, (res) => {
                    createVariants(res);
                    setVariants([]);
                });
            }
        }

        message.info('Đăng sản phẩm thành công');
        setProduct(NEW_PRODUCT);
    };

    const onFinishUploadImage = (name, url) => {
        const newImg = {
            name,
            uid: name,
            url,
        };
        const newList = [...((product && product.imgs) || []), newImg];
        onChangeValue('imgs', newList);
    };

    const onRemoveImage = (id) => {
        const imgList = [...(product.imgs || [])];
        const newImgList = imgList.filter((img) => img.uid !== id);
        onChangeValue('imgs', newImgList);
        if (id) {
            BoService.updatePost({ ...product, imgs: newImgList, enabled: true });
        }
    };

    const onFinishUploadImageVariant = (name, url, index) => {
        const newImg = {
            name,
            uid: name,
            url,
        };

        const newList = [...((variants[index] && variants[index].imgs) || []), newImg];
        onChangeVariantValue(index, 'imgs', newList);
    };

    const onRemoveImageVariant = (id, index) => {
        const imgList = [...(variants[index].imgs || [])];
        const newImgList = imgList.filter((img) => img.uid !== id);
        onChangeVariantValue(index, 'imgs', newImgList);
        if (id) {
            BoService.updatePostVariant({ ...product }, { ...variants[index], imgs: newImgList, enabled: true });
        }
    };

    const onRemoveVariant = (index) => {
        const newVariants = [...variants];
        const variant = newVariants.splice(index, 1);
        setVariants(newVariants);
        if (variant.id) {
            if (role === ROLE.SHOP) {
                BoService.removeShopProductVariant(variant);
            } else {
                BoService.removePostVariant(variant);
            }
        }
    };

    const onFinishUploadVideo = (name, url) => {
        const newVideos = {
            name,
            uid: name,
            url,
        };
        const newList = [...((product && product.videos) || []), newVideos];
        onChangeValue('videos', newList);
    };

    const onRemoveVideo = (id) => {
        const videoList = [...(product.videos || [])];
        console.log(videoList);
        const newVideoList = videoList.filter((video) => video.uid !== id);
        onChangeValue('videos', newVideoList);
        if (id) {
            BoService.updatePost({ ...product, videos: newVideoList, enabled: true });
        }
    };

    const addVariant = () => {
        const newVariant = { ...NEW_PRODUCT, imgs: [] };
        const newList = [...variants];
        newList.push(newVariant);
        setVariants(newList);
    };

    return (
        <div>
            <Card className="w-full md:w-1/3 p-1 mt-2">
                <span className="text-lg font-bold">{id ? 'Cập nhật' : 'Thêm sản phẩm'}</span>
                <ImageUploader
                    onFinishUploadImage={onFinishUploadImage}
                    imgList={product.imgs || []}
                    maxFiles={10}
                    removeImage={onRemoveImage}
                />
                <VideoUploader
                    onFinishUploadImage={onFinishUploadVideo}
                    imgList={product.videos || []}
                    maxFiles={1}
                    removeImage={onRemoveVideo}
                />
                <div className="flex  mt-5">
                    <div className="w-1/3">Tên sản phẩm</div>
                    <Input value={product.name} onChange={(e) => onChangeValue('name', e.target.value)}></Input>
                </div>
                <div className="flex  mt-5">
                    <div className="w-1/3">Mã sản phẩm</div>
                    <Input value={product.code} onChange={(e) => onChangeValue('code', e.target.value)}></Input>
                </div>
                <div className="flex  mt-5">
                    <div className="w-1/3">Đơn vị</div>
                    <Input value={product.unit} onChange={(e) => onChangeValue('unit', e.target.value)}></Input>
                </div>
                <div className="flex  mt-5">
                    <div className="w-1/3">Đơn giá</div>
                    <InputNumber
                        className="w-2/3"
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        value={product.price}
                        onChange={(e) => onChangeValue('price', e)}
                    ></InputNumber>
                </div>
                <div className="flex  mt-5">
                    <div className="w-1/3">Miêu tả</div>
                    <Input.TextArea value={product.desc} onChange={(e) => onChangeValue('desc', e.target.value)}></Input.TextArea>
                </div>
                <div className="mt-2">
                    {variants.length > 0 && (
                        <Card>
                            {variants.map((variant, index) => {
                                return (
                                    <div className="mb-5">
                                        <span>Biến thể {index + 1}</span>
                                        <ImageUploader
                                            onFinishUploadImage={(name, url) => onFinishUploadImageVariant(name, url, index)}
                                            imgList={variant.imgs || []}
                                            maxFiles={1}
                                            removeImage={(id) => onRemoveImageVariant(id, index)}
                                        />
                                        <Input
                                            placeholder="Tên biến thể"
                                            value={variant.name}
                                            onChange={(e) => onChangeVariantValue(index, 'name', e.target.value)}
                                        />
                                        <Input
                                            placeholder="Giá"
                                            value={variant.price}
                                            onChange={(e) => onChangeVariantValue(index, 'price', e.target.value)}
                                        />
                                        <Button type="primary" danger onClick={() => onRemoveVariant(index)}>
                                            Xóa
                                        </Button>
                                    </div>
                                );
                            })}
                        </Card>
                    )}
                    <Button type="primary" className="mr-5" onClick={addVariant}>
                        Thêm biến thể
                    </Button>
                </div>
            </Card>
            <div className="flex flex-row mt-2">
                <Button type="primary" className="mr-5" onClick={onSave}>
                    Lưu
                </Button>
                <Button type="dashed" href="/bo/products">
                    Hủy
                </Button>
            </div>
        </div>
    );
};
