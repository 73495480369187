import { Button, Card, Input, InputNumber, message, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ImageUploader } from '../../components/ImageUploader';
import { ROLE } from '../../constant';
import { BoService, getAllProvider, getDistrict, getWards } from '../../services/Service';

const NEW_SHOP_INFO = {
    name: '',
    address: '',
    managerId: '',
    imgs: [],
    provine: null,
    district: null,
};

export const ShopProfile = () => {
    const userId = localStorage.getItem('userId');
    const [shopInfo, setShopInfo] = useState(NEW_SHOP_INFO);
    const [shopData, setShopData] = useState(null);
    const [provines, setProvines] = useState([]);
    const [districts, setDistrict] = useState([]);
    const [wards, setWards] = useState([]);

    useEffect(() => {
        getAllProvider().then((res) => setProvines(res));
    }, []);

    useEffect(() => {
        if (userId) {
            BoService.getShopAccount(userId, (res) => {
                setShopInfo(res.info);
                setShopData(res);
            });
        }
    }, [userId]);

    useEffect(() => {
        console.log(shopInfo);
        if (shopInfo.provine) {
            getDistrict(shopInfo.provine.code).then((res) => setDistrict(res.districts || []));
        }
        if (shopInfo.district) {
            getWards(shopInfo.district.code).then((res) => setWards(res.wards || []));
        }
    }, [shopInfo]);

    const onChangeValue = (field, value) => {
        const newProduct = { ...shopInfo };
        newProduct[field] = value;
        if (field === 'provine') {
            const provine = (provines || []).find((pr) => pr.code == value);
            console.log(provine, value);
            newProduct[field] = { ...provine, code: value };
        }
        if (field === 'district') {
            const district = (districts || []).find((dis) => dis.code == value);
            newProduct[field] = { ...district, code: value };
        }
        if (field === 'ward') {
            const ward = (wards || []).find((wa) => wa.code == value);
            newProduct[field] = { ...ward, code: value };
        }
        setShopInfo(newProduct);
    };

    const onSave = () => {
        if (!shopInfo.name || !shopInfo.address) {
            message.error('Vui lòng nhập đầy đủ thông tin');
            return;
        }
        console.log({ ...shopData, info: { ...shopInfo } });
        BoService.updateShopAccount({ ...shopData, info: { ...shopInfo } });
        message.info('Thay đổi thông tin thành công');
    };

    const onFinishUploadImage = (name, url) => {
        const newImg = {
            name,
            uid: name,
            url,
        };
        const newList = [newImg];
        onChangeValue('imgs', newList);
    };

    const onRemoveImage = (id) => {
        const imgList = [];
        onChangeValue('imgs', imgList);
    };
    console.log(provines);
    return (
        <div>
            <Card className="w-full md:w-1/3 p-1 mt-2">
                <span className="text-lg font-bold">{'Cập nhật shop'}</span>
                <ImageUploader
                    onFinishUploadImage={onFinishUploadImage}
                    imgList={shopInfo.imgs || []}
                    maxFiles={10}
                    removeImage={onRemoveImage}
                />
                <div className="flex  mt-5">
                    <div className="w-1/3">Tên shop</div>
                    <Input value={shopInfo.name} onChange={(e) => onChangeValue('name', e.target.value)}></Input>
                </div>
                <div className="flex  mt-5">
                    <div className="w-1/3">Địa chỉ</div>
                    <Input value={shopInfo.address} onChange={(e) => onChangeValue('address', e.target.value)}></Input>
                </div>
                <div className="flex  mt-5">
                    <div className="w-1/3">Tỉnh thành</div>
                    <Select
                        className="w-full mt-2"
                        value={shopInfo.provine && shopInfo.provine.code}
                        onChange={(value) => onChangeValue('provine', value)}
                        placeholder="Chọn tỉnh thành"
                    >
                        {provines.map((provine, index) => {
                            return <Select.Option key={provine.code}>{provine.name}</Select.Option>;
                        })}
                    </Select>
                </div>
                {districts.length > 0 && (
                    <div className="flex  mt-5">
                        <div className="w-1/3">Quận</div>
                        <Select
                            className="w-full mt-2"
                            value={shopInfo.district && shopInfo.district.code}
                            onChange={(value) => onChangeValue('district', value)}
                            placeholder="Chọn quận"
                        >
                            {districts.map((district, index) => {
                                return <Select.Option key={district.code}>{district.name}</Select.Option>;
                            })}
                        </Select>
                    </div>
                )}
                {wards.length > 0 && (
                    <div className="flex  mt-5">
                        <div className="w-1/3">Phường</div>
                        <Select
                            className="w-full mt-2"
                            value={shopInfo.ward && shopInfo.ward.code}
                            onChange={(value) => onChangeValue('ward', value)}
                            placeholder="Chọn phường"
                        >
                            {wards.map((ward, index) => {
                                return <Select.Option key={ward.code}>{ward.name}</Select.Option>;
                            })}
                        </Select>
                    </div>
                )}
            </Card>
            <div className="flex flex-row mt-2">
                <Button type="primary" className="mr-5" onClick={onSave}>
                    Lưu
                </Button>
                <Button type="dashed" href="/bo">
                    Hủy
                </Button>
            </div>
        </div>
    );
};
