import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { getGroupManagerList } from '../../services/Service';
import { GroupManagerItem } from './GroupManagerItem';

export const GroupManagerList = () => {
    const [groupManagers, setGroupManagers] = useState([]);
    useEffect(() => {
        getGroupManagerList((res) => setGroupManagers(res));
    }, []);
    return (
        <div className="flex p-2 flex-col">
            <Button type="primary" className="mt-2 w-full md:w-2/12 lg:w-2/12 xl:w-1/12" href="/bo/group-manager/new">
                Thêm quản lý
            </Button>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-3 mt-2">
                {groupManagers.map((manager) => {
                    return <GroupManagerItem key={manager.id} manager={manager} />;
                })}
            </div>
        </div>
    );
};
