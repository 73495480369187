import { Button, Card, DatePicker, Modal, Select, Switch } from 'antd';
import { useEffect, useState } from 'react';
import { ORDER_STATUS, ROLE } from '../../constant';
import { BoService } from '../../services/Service';
import { formatMoney } from '../../utils/Utils';
import { Bill } from '../buy-form/Bill';

const NUMBER_OF_FLOOR = 12;
export const OrderList = () => {
    const role = localStorage.getItem('role');
    const userData = JSON.parse(localStorage.getItem('userData'));
    const apartmentId = localStorage.getItem('apartmentId');
    const [orders, setOrders] = useState([]);
    const [isDetailVisible, setDetailVisible] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedFloor, setSelectedFloor] = useState('ALL');
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [summary, setSummary] = useState(null);
    const [summaryVisible, setSummaryVisible] = useState(false);
    const [apartments, setApartments] = useState([]);
    const [selectedApartment, setSelectedApartment] = useState('ALL');
    const [selectedStatus, setSelectedStatus] = useState('ALL');
    const [selectedPaid, setSelectedPaid] = useState('ALL');

    const onGetOrders = (list) => {
        if (role === 'admin') {
            setOrders(list);
            return;
        }
        if (role === 'group-manager') {
            const newList = list.filter((item) => item.apartmentInfo.id === apartmentId);
            setOrders(newList);
            return;
        }
        if (role === ROLE.CUSTOMER) {
            console.log(list, userData);
            const newList = list.filter((item) => {
                console.log(item.userInfo);
                return   item.userInfo.phone === userData.contactNo;
            });
            setOrders(newList);
            return;
        }
        if (role === ROLE.SHOP) {
            const newList = list.filter((item) => item.apartmentInfo.managerId === userData.id);

            setOrders(newList);
            return;
        }
    };

    useEffect(() => {
        if (role === ROLE.SHOP || role === ROLE.CUSTOMER) {
            BoService.getShopOrders(onGetOrders);
            return;
        }

        BoService.getOrders(onGetOrders);
        if (role !== 'group-manager') {
            BoService.getApartments(setApartments);
        }
    }, []);

    useEffect(() => {
        let allOrders = [...orders];
        if (selectedFloor !== 'ALL') {
            allOrders = allOrders.filter((order) => {
                const floor = order.userInfo.room.charAt(0);
                return floor === selectedFloor;
            });
        }

        if (selectedApartment !== 'ALL') {
            allOrders = allOrders.filter((order) => {
                const apartmentInfo = order.apartmentInfo;
                return apartmentInfo && apartmentInfo.id === selectedApartment;
            });
        }

        if (selectedStatus !== 'ALL') {
            allOrders = allOrders.filter((order) => {
                return order.status === selectedStatus;
            });
        }

        if (selectedPaid !== 'ALL') {
            allOrders = allOrders.filter((order) => {
                const paid = order.isPaid ? 'PAID' : 'UNPAID';
                return selectedPaid === paid;
            });
        }
        allOrders = allOrders.filter((order) => {
            const date = new Date(order.createdDate.seconds * 1000);
            if (
                date.getDate() === selectedDate.getDate() &&
                date.getMonth() === selectedDate.getMonth() &&
                date.getYear() === selectedDate.getYear()
            ) {
                return true;
            }
            return false;
        });

        setFilteredOrders(allOrders);
    }, [orders, selectedDate, selectedFloor, selectedApartment, selectedStatus, selectedPaid]);

    useEffect(() => {
        const summaryCart = [];
        filteredOrders.map((order) => {
            const newOrder = { ...order };
            const cart = [...newOrder.cart];
            (cart || []).map((item) => {
                const foundIndex = summaryCart.findIndex((product) => {
                    return product.id === item.id;
                });
                if (foundIndex >= 0) {
                    summaryCart[foundIndex].quantity += item.quantity;
                    return;
                }
                summaryCart.push({ ...item });
            });
        });
        console.log(summaryCart);
        setSummary(summaryCart);
    }, [filteredOrders]);

    const handleSelectOrder = (order) => {
        setSelectedOrder(order);
        setDetailVisible(true);
    };

    const onDateChange = (date, dateString) => {
        setSelectedDate(new Date(dateString));
    };

    const updateOrderStatus = (order, status) => {
        if (role === ROLE.SHOP) {
            BoService.updateShopOrderStatus(order, status, () => {
                BoService.getShopOrders(onGetOrders);
            });
            return;
        }
        BoService.updateOrderStatus(order, status, () => {
            BoService.getOrders(setOrders);
        });
    };

    const updateOrderPaid = (order, isPaid) => {
        if (role === ROLE.SHOP) {
            BoService.updateShopOrderPaid(order, isPaid, () => {
                BoService.getShopOrders(onGetOrders);
            });
            return;
        }
        BoService.updateOrderPaid(order, isPaid, () => {
            BoService.getOrders(setOrders);
        });
    };
    return (
        <div className="mt-3">
            <div>
                <Button type="primary" onClick={() => setSummaryVisible(true)}>
                    Tổng kết
                </Button>
            </div>
            <div className="mt-3">
                <DatePicker onChange={onDateChange} className="mr-2" />
                {role === 'admin' && (
                    <Select value={selectedFloor} onChange={(value) => setSelectedFloor(value)} placeholder="Chọn tầng">
                        <Select.Option key="ALL">Tất cả</Select.Option>
                        {new Array(NUMBER_OF_FLOOR).fill(1).map((value, index) => {
                            return <Select.Option key={(index + 1).toString()}>Tầng {index + 1}</Select.Option>;
                        })}
                    </Select>
                )}

                <div className="mt-2">
                    {role === 'admin' && (
                        <Select
                            className="w-full md:w-1/4"
                            value={selectedApartment}
                            onChange={(value) => setSelectedApartment(value)}
                            placeholder="Chọn tầng"
                        >
                            <Select.Option key="ALL">Tất cả</Select.Option>
                            {apartments.map((value, index) => {
                                return <Select.Option key={value.id}>{value.name}</Select.Option>;
                            })}
                        </Select>
                    )}
                    <Select
                        className="md:ml-2 w-full md:w-1/4"
                        value={selectedStatus}
                        onChange={(value) => setSelectedStatus(value)}
                        placeholder="Chọn tầng"
                    >
                        <Select.Option key="ALL">Tất cả</Select.Option>
                        {Object.keys(ORDER_STATUS).map((value, index) => {
                            return <Select.Option key={value}>{value}</Select.Option>;
                        })}
                    </Select>
                    <Select className="md:ml-2 w-full md:w-1/4" value={selectedPaid} onChange={(value) => setSelectedPaid(value)}>
                        <Select.Option key="ALL">Tất cả</Select.Option>
                        <Select.Option key="PAID">Đã thanh toán</Select.Option>
                        <Select.Option key="UNPAID">Chưa thanh toán</Select.Option>
                    </Select>
                </div>
            </div>
            <Modal visible={isDetailVisible} onCancel={() => setDetailVisible(false)} onOk={() => setDetailVisible(false)}>
                {selectedOrder && (
                    <Bill
                        userInfo={selectedOrder.userInfo}
                        cart={selectedOrder.cart}
                        apartmentInfo={selectedOrder.apartmentInfo}
                    />
                )}
            </Modal>
            <Modal visible={summaryVisible} onCancel={() => setSummaryVisible(false)} onOk={() => setSummaryVisible(false)}>
                {summary && <Bill cart={summary} />}
            </Modal>
            <div className="grid grid-cols-1 gap-2 md:gap-3 md:grid-cols-3 lg:grid-cols-4 mt-3">
                {filteredOrders.map((order) => {
                    const date = new Date(order.createdDate.seconds * 1000);
                    const cart = order.cart || [];
                    let totalPrice = 0;
                    (cart || []).map((item) => {
                        totalPrice += item.price * item.quantity;
                    });
                    return (
                        <Card hoverable key={order.id}>
                            <div className="flex w-full">
                                <span className="mr-2 mb-2">Đã thanh toán</span>
                                {role !== ROLE.CUSTOMER && (
                                    <Switch checked={order.isPaid} onChange={(checked) => updateOrderPaid(order, checked)} />
                                )}
                            </div>
                            <div onClick={() => handleSelectOrder(order)}>
                                <span>
                                    Ngày: {date.getDate()}/{date.getMonth()}/{date.getFullYear()}
                                </span>
                                <br />
                                <span>
                                    {order.apartmentInfo.type === 'apartment' ? 'Phòng' : 'Địa chỉ'}
                                    <span className="font-bold">{order.userInfo.room}</span>
                                </span>
                                <br />
                                <span>
                                    Tổng cộng: <span className="font-bold">{formatMoney(totalPrice)} VND</span>
                                </span>
                            </div>
                            {role === ROLE.CUSTOMER ? (
                                <span>{order.status}</span>
                            ) : (
                                <Select
                                    className="w-full mt-2"
                                    value={order.status}
                                    onChange={(value) => updateOrderStatus(order, value)}
                                    placeholder="Chọn trạng thái"
                                >
                                    {Object.keys(ORDER_STATUS).map((value, index) => {
                                        return <Select.Option key={value}>{value}</Select.Option>;
                                    })}
                                </Select>
                            )}
                        </Card>
                    );
                })}
            </div>
        </div>
    );
};
