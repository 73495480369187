import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Card, message, Popconfirm } from 'antd';

export const GroupManagerItem = ({ manager, onRemove }) => {
    return (
        <Card hoverable>
            <div className="flex flex-col">
                <span>Tên đăng nhập: {manager.username}</span>
                <span>Mật khẩu: {manager.password}</span>
                <span>Tên: {manager.contactName}</span>
                <span>Số điện thoại: {manager.contactNo}</span>

                <div className="flex w-full justify-between">
                    <a href={`/bo/group-manager/edit/${manager.id}`} style={{ height: 20, fontSize: 20 }} className="mr-3">
                        <EditOutlined size={30} />
                    </a>
                    {/* <div style={{ height: 20, fontSize: 20 }} className="mr-3">
                        <Popconfirm
                            placement="top"
                            title={'Xóa'}
                            onConfirm={() => onRemove(manager)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="text">
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </div> */}
                </div>
            </div>
        </Card>
    );
};
