import { Button, Input, notification, Tabs } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { BoService, UserService } from './services/Service';

const { TabPane } = Tabs;

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const history = useHistory();

    const onLogedBO = (user) => {
        if (user) {
            BoService.setUserData(user.username, user.id, user);
            history.push('/bo');
            window.location.reload();
            return;
        }
        notification['warning']({
            message: 'Login thất bại',
            description: 'Username hoặc Password không đứng',
        });
    };

    const loginUser = () => {
        BoService.login(username, password, onLogedBO);
    };
    return (
        <div className="flex flex-1 items-center justify-center p-3" style={{ backgroundColor: '#679AFF' }}>
            <div style={{ background: 'white' }} className="p-3">
                <div>
                    <div>
                        <span className="primary-text">Tên đăng nhập</span>
                        <Input value={username} onChange={(e) => setUsername(e.target.value)}></Input>
                    </div>
                    <div>
                        <span className="primary-text">Mật khẩu</span>
                        <Input value={password} type="password" onChange={(e) => setPassword(e.target.value)}></Input>
                    </div>
                    <Button type="danger" onClick={loginUser} className="w-full mt-3">
                        Đăng nhập
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Login;
