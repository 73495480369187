import { SearchOutlined } from '@ant-design/icons';
import { Card, Modal, Input, Space, Button, Drawer, InputNumber } from 'antd';
import { useEffect, useState } from 'react';
import { NO_IMAGE_URL } from '../../constant';
import { escapeRegExp, formatMoney, stringToSlug } from '../../utils/Utils';
import { ProductItem } from './ProductItem';

export const ItemPickForm = ({ products, carts, onSelect, onChangeCart, searchable = true }) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [searchKey, setSearchKey] = useState('');
    const [filteredProducts, setFilteredProducts] = useState([]);
    useEffect(() => {
        let list = [...(products || [])];
        if (!searchKey) {
            setFilteredProducts(list);
            return;
        }
        const formatedKey = stringToSlug(searchKey);
        const query = escapeRegExp(formatedKey);
        const regex = new RegExp(`${query.trim()}`, 'i');
        list = products.filter((product) => {
            const name = stringToSlug(product.name);
            console.log(name, query, name.search(regex));
            return name.search(regex) >= 0;
        });
        setFilteredProducts(list);
    }, [searchKey, products]);

    // const renderProduct = (product)=> {}

    return (
        <div className="w-full">
            {searchable && (
                <Input
                    className="mt-5"
                    value={searchKey}
                    placeholder="Nhập từ khóa tìm kiếm"
                    onChange={(e) => setSearchKey(e.target.value)}
                    prefix={<SearchOutlined />}
                />
            )}
            <div className="grid grid-cols-2 md:grid-cols-4 mt-3 gap-3" style={{ maxWidth: '100%', minHeight: '300px' }}>
                {' '}
                {(filteredProducts || []).map((product) => {
                    const isAlreadInCart = (carts || []).some((cart) => cart.id === product.id);
                    const cartProduct = carts.find((pr) => pr.id === product.id);
                    let quantity = 0;
                    if (cartProduct) {
                        quantity = cartProduct.quantity;
                    }
                    return (
                        <div style={{ minWidth: '150px' }} className="mx-2">
                            <ProductItem
                                product={product}
                                isNoneSelectable={false}
                                isSelected={selectedProduct && selectedProduct.id === product.id}
                                onSelected={() => {
                                    if (onSelect) {
                                        onSelect(product);
                                    }
                                    setSelectedProduct(product);
                                }}
                            />
                            <div className="flex flex-row items-center">
                                <div className="flex flex-row items-center">
                                    <InputNumber
                                        className="w-1/3"
                                        value={quantity}
                                        onChange={(e) => onChangeCart(product.id, 'quantity', e)}
                                    ></InputNumber>
                                    <div className="font-bold ml-1">{product.unit}</div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
