import { Card } from 'antd';
import { NO_IMAGE_URL } from '../../constant';
import { formatMoney } from '../../utils/Utils';

export const ProductItem = ({ product, isNoneSelectable = false, isSelected = false, onSelected }) => {
    return (
        <Card
            hoverable
            style={{ width: '100%', borderRadius: '10px' }}
            bodyStyle={{ padding: '0px' }}
            key={product.id}
            onClick={() => !isNoneSelectable && onSelected(product)}
            className={`item-pick ${isSelected && 'selected'}`}
        >
            {isNoneSelectable && <div className="none-select-cover"></div>}
            <img
                alt="example"
                style={{
                    width: '100%',
                    height: '150px',
                    objectFit: 'cover',
                    borderRadius: '10px 10px 0px 0px',
                }}
                src={(product.imgs && product.imgs[0] && product.imgs[0].url) || NO_IMAGE_URL}
            />
            <div className="flex flex-col items-center p-2 w-full">
                <span className="text-xs text-left w-full overflow-ellipsis overflow-hidden ..." style={{ height: '35px' }}>
                    {product.name}
                </span>
                <div className="flex items-center justify-between w-full">
                    <span className=" text-sm md:text-base font-bold primary-text">
                        {formatMoney(product.price)}/{product.unit}
                    </span>
                </div>
            </div>
        </Card>
    );
};
