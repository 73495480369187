import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { BoService, getGroupManager } from '../services/Service';

export const Footer = () => {
    let { id, shopId } = useParams();
    const [apartmentInfo, setApartmentInfo] = useState(null);
    const [groupManager, setGroupManager] = useState(null);

    useEffect(() => {
        if (!apartmentInfo) {
            return;
        }
        getGroupManager(apartmentInfo.managerId, (res) => {
            console.log(res);
            setGroupManager(res);
        });
    }, [apartmentInfo]);

    useEffect(() => {
        if (id) {
            BoService.getApartmentInfo(id, (data) => {
                setApartmentInfo(data);
                console.log(data);
            });
        }
        if (shopId) {
            BoService.getShopInfo(shopId, (data) => {
                setApartmentInfo(data);
                console.log(data);
            });
        }
    }, []);

    return (
        <div style={{ background: '#0981de' }} className="flex flex-col items-center justify-center text-white p-2">
            <span>Mọi vấn đề xin liên hệ</span>
            <span> {(groupManager && groupManager.contactName) || 'Đinh Văn Thuận'}</span>
            {(groupManager && groupManager.contactNo && (
                <a href={`tel:${groupManager.contactNo}`}>{groupManager.contactNo}</a>
            )) || <a href="tel:0333366843">0333366843</a>}
        </div>
    );
};
