import { Avatar, Button, Card, Input, Select, Tabs } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { NO_IMAGE_URL } from '../constant';
import { BoService, getAllProvider, getDistrict, getWards, UserService } from '../services/Service';
import { escapeRegExp, formatMoney, stringToSlug } from '../utils/Utils';
import { ItemPickForm } from '../components/buy-form/ItemPickForm';

import { ROLE } from '../constant';
export const Home = ({}) => {
    const userId = localStorage.getItem('userId');
    const [searchKey, setSearchKey] = useState('');
    const [shopList, setShopList] = useState([]);
    const [shopListByCode, setShopListByCode] = useState([]);
    const [productList, setProductList] = useState([]);
    const [accountss, setAccounts] = useState([]);

    const [filteredShop, setFilteredShops] = useState([]);
    const [filteredProducts, setFilteredProduct] = useState([]);
    const [filteredProductsByCode, setFilteredProductByCode] = useState([]);
    const [filtedAccount, setFilteredAccount] = useState([]);

    const [provines, setProvines] = useState([]);
    const [districts, setDistrict] = useState([]);
    const [wards, setWards] = useState([]);
    const [selectedProvine, setSelectedProvine] = useState(null);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [selectedWard, setSelectedWard] = useState(null);

    useEffect(() => {
        let list = [...(accountss || []).filter((account) => account.role === ROLE.CUSTOMER)];
        if (!searchKey) {
            setFilteredAccount(list);
            return;
        }
        const formatedKey = stringToSlug(searchKey);
        const query = escapeRegExp(formatedKey);
        const regex = new RegExp(`${query.trim()}`, 'i');
        list = accountss.filter((account) => {
            if (account.role !== ROLE.CUSTOMER) {
                return null;
            }
            const name = stringToSlug(account.contactName);
            return name.search(regex) >= 0;
        });

        setFilteredAccount(list);
    }, [searchKey, accountss]);

    useEffect(() => {
        let list = [...(shopList || [])];
        if (!searchKey) {
            setFilteredShops(list);
            setShopListByCode(list);
            return;
        }
        const formatedKey = stringToSlug(searchKey);
        const query = escapeRegExp(formatedKey);
        const regex = new RegExp(`${query.trim()}`, 'i');
        list = shopList.filter((shop) => {
            const name = stringToSlug(shop.name);
            console.log(name, query, name.search(regex));
            return name.search(regex) >= 0;
        });
        const listByCode = shopList.filter((shop) => {
            const name = stringToSlug(shop.code);
            console.log(name, query, name.search(regex));
            return name.search(regex) >= 0;
        });

        setFilteredShops(list);
        setShopListByCode(listByCode);
    }, [searchKey, shopList]);

    useEffect(() => {
        let list = [...(productList || [])];
        if (!searchKey) {
            setFilteredProduct(list);
            return;
        }
        const formatedKey = stringToSlug(searchKey);
        const query = escapeRegExp(formatedKey);
        const regex = new RegExp(`${query.trim()}`, 'i');

        list = productList.filter((product) => {
            const name = stringToSlug(product.name);
            console.log(name, query, name.search(regex));
            return name.search(regex) >= 0;
        });

        setFilteredProduct(list);

        const listByCode = productList.filter((product) => {
            const name = stringToSlug(product.code);
            console.log(name, query, name.search(regex));
            return name.search(regex) >= 0;
        });

        setFilteredProductByCode(listByCode);
    }, [searchKey, productList]);

    useEffect(() => {
        getAllProvider().then((res) => setProvines(res));
    }, []);

    useEffect(() => {
        BoService.getShopList((res) => setShopList(res));
        BoService.getShopProduct((res) => setProductList(res));
        BoService.getAllAccount((res) => setAccounts(res));
    }, []);

    useEffect(() => {
        if (selectedProvine) {
            getDistrict(selectedProvine).then((res) => setDistrict(res.districts || []));
        }
        if (selectedDistrict) {
            getWards(selectedDistrict).then((res) => setWards(res.wards || []));
        }
    }, [selectedProvine, selectedDistrict]);

    useEffect(() => {
        let list = [...(shopList || [])];
        // if (!searchKey) {
        //     setFilteredShops(list);
        //     return;
        // }
        const formatedKey = stringToSlug(searchKey);
        const query = escapeRegExp(formatedKey);
        const regex = new RegExp(`${query.trim()}`, 'i');

        list = shopList.filter((shop) => {
            console.log(shop);
            const name = stringToSlug(shop.info.name || '');
            return name.search(regex) >= 0;
        });

        if (selectedProvine) {
            list = list.filter((shop) => shop.info && shop.info.provine && shop.info.provine.code == selectedProvine);
            console.log(selectedProvine, list);
        }
        if (selectedDistrict) {
            list = list.filter((shop) => shop.info && shop.info.district && shop.info.district.code == selectedDistrict);
        }
        if (selectedWard) {
            list = list.filter((shop) => shop.info && shop.info.ward && shop.info.ward.code == selectedWard);
        }

        setFilteredShops(list);
    }, [searchKey, shopList, selectedProvine, selectedDistrict, selectedWard]);

    const addFriend = (accountId) => {
        UserService.addFriend(accountId);
        const accounts = [...filtedAccount].map((account) => {
            if (account.id === accountId) {
                const newAccount = { ...account };
                newAccount.friendIds = [...(newAccount.friendIds || []), userId];
                return newAccount;
            }
            return account;
        });
        setFilteredAccount(accounts);
    };

    const accountList = (
        <>
            <div className="grid grid-cols-2 md:grid-colds-5 gap-2">
                {filtedAccount.map((account) => (
                    <div className="flex p-5">
                        <Avatar />
                        <div className="flex flex-col ml-3">
                            <span>{account.contactName || account.username}</span>
                            <div className="flex gap-2">
                                <a href={`/chat/${account.id}`}>
                                    <Button style={{ width: '100px' }} type="primary">
                                        Nhắn tin
                                    </Button>
                                </a>
                                {!(account.friendIds || []).find((friendId) => friendId === userId) && (
                                    <Button style={{ width: '100px' }} type="primary" onClick={() => addFriend(account.id)}>
                                        Kết bạn
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );

    const shopNormalList = (
        <>
            <span className="font-bold text-lg">Danh sách shop</span>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-3">
                {filteredShop.map((shop) => {
                    if (!shop.info) {
                        return;
                    }
                    return (
                        <a href={`/shop/${shop.id}`}>
                            <div>
                                <img
                                    src={(shop.info.imgs && shop.info.imgs[0].url) || NO_IMAGE_URL}
                                    alt="name"
                                    style={{ height: '100px' }}
                                    className="w-full object-cover"
                                />

                                <div className="flex flex-col">
                                    <span className="text-base font-bold text-blue-500">{shop.info.name}</span>
                                    <span className="text-xs primary">{shop.info.address}</span>
                                </div>
                            </div>
                        </a>
                    );
                })}
            </div>
        </>
    );

    const shopsByCode = (
        <>
            <span className="font-bold text-lg">Danh sách shop</span>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-3">
                {shopListByCode.map((shop) => {
                    if (!shop.info) {
                        return;
                    }
                    return (
                        <a href={`/shop/${shop.id}`}>
                            <div>
                                <img
                                    src={(shop.info.imgs && shop.info.imgs[0].url) || NO_IMAGE_URL}
                                    alt="name"
                                    style={{ height: '100px' }}
                                    className="w-full object-cover"
                                />

                                <div className="flex flex-col">
                                    <span className="text-base font-bold text-blue-500">{shop.info.name}</span>
                                    <span className="text-xs primary">{shop.info.address}</span>
                                </div>
                            </div>
                        </a>
                    );
                })}
            </div>
        </>
    );

    const productListByName = (
        <>
            <Card className="w-full  flex justify-center" style={{ height: 'fit-content' }} bodyStyle={{ width: '100%' }}>
                <ItemPickForm
                    products={filteredProducts}
                    carts={[]}
                    searchable={false}
                    onChangeCart={(productId, field, value) => console.log('Not change')}
                    onSelect={(pr) => window.open(`/shop/${pr.owner}`)}
                />
            </Card>
        </>
    );

    const productListByCode = (
        <>
            <Card className="w-full  flex justify-center" style={{ height: 'fit-content' }} bodyStyle={{ width: '100%' }}>
                <ItemPickForm
                    products={filteredProductsByCode}
                    carts={[]}
                    searchable={false}
                    onChangeCart={(productId, field, value) => console.log('Not change')}
                    onSelect={(pr) => window.open(`/shop/${pr.owner}`)}
                />
            </Card>
        </>
    );

    return (
        <div className="bg-container flex justify-center align-items-center flex-1">
            <div className="w-full md:w-3/5 page-content flex flex-col">
                <div className="w-full h-40 flex justify-center items-center">
                    <img
                        src={'https://i-english.vnecdn.net/2018/12/16/ST-1544953644-5978-1544953647_680x0.jpg'}
                        alt="st"
                        className="w-full h-full object-cover"
                    />
                    <span className="absolute font-bold text-xl p-2" style={{ borderRadius: 20, backgroundColor: '#ffffffbf' }}>
                        Chào mừng quí khách đến với shop
                    </span>
                </div>
                <Input
                    className="mt-5"
                    value={searchKey}
                    placeholder="Nhập từ khóa tìm kiếm"
                    onChange={(e) => setSearchKey(e.target.value)}
                    prefix={<SearchOutlined />}
                />
                <div>
                    <div className="flex  mt-5">
                        <div className="w-1/3">Tỉnh thành</div>
                        <Select
                            allowClear
                            className="w-full mt-2"
                            value={selectedProvine}
                            onChange={(value) => setSelectedProvine(value)}
                            placeholder="Chọn tỉnh thành"
                        >
                            {provines.map((provine, index) => {
                                return <Select.Option key={provine.code}>{provine.name}</Select.Option>;
                            })}
                        </Select>
                    </div>
                    {districts.length > 0 && (
                        <div className="flex  mt-5">
                            <div className="w-1/3">Quận</div>
                            <Select
                                allowClear
                                className="w-full mt-2"
                                value={selectedDistrict}
                                onChange={(value) => setSelectedDistrict(value)}
                                placeholder="Chọn quận"
                            >
                                {districts.map((district, index) => {
                                    return <Select.Option key={district.code}>{district.name}</Select.Option>;
                                })}
                            </Select>
                        </div>
                    )}
                    {wards.length > 0 && (
                        <div className="flex  mt-5">
                            <div className="w-1/3">Phường</div>
                            <Select
                                allowClear
                                className="w-full mt-2"
                                value={selectedWard}
                                onChange={(value) => setSelectedWard(value)}
                                placeholder="Chọn phường"
                            >
                                {wards.map((ward, index) => {
                                    return <Select.Option key={ward.code}>{ward.name}</Select.Option>;
                                })}
                            </Select>
                        </div>
                    )}
                </div>
                <Card className="w-full flex flex-col mt-3">
                    <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab="Tất cả shop" key="1">
                            {shopNormalList}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Sản phẩm" key="product">
                            {productListByName}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Mã shop" key="2">
                            {shopsByCode}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Mã sản phẩm" key="productbycode">
                            {productListByCode}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Người dùng" key="people">
                            {accountList}
                        </Tabs.TabPane>
                    </Tabs>
                </Card>
            </div>
        </div>
    );
};
