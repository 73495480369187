const moneyFormatter = new Intl.NumberFormat();
export const formatMoney = (amount) => {
    if (!amount) return 0;
    const value = parseInt(amount) || 0;
    return moneyFormatter.format(value);
};

export const calculateSalePrice = (rateData, item, selectedOption) => {
    const rate = item
        ? (rateData[item.originalCur] &&
              rateData[item.originalCur][item.saleCur.trim()] &&
              parseFloat(rateData[item.originalCur][item.saleCur.trim()])) ||
          1
        : 1;

    const salePrice = selectedOption
        ? parseFloat((selectedOption && selectedOption.salePrice) || selectedOption.salePrice || 0) * parseFloat(rate)
        : item.salePrice * parseFloat(rate);
    return salePrice;
};

export const stringToSlug = (str) => {
    if (!str) {
        return '';
    }
    // remove accents
    var from = 'àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ',
        to = 'aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy';
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(RegExp(from[i], 'gi'), to[i]);
    }

    str = str
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9\-]/g, '')
        .replace(/-+/g, '');

    return str;
};

export const escapeRegExp = (str) => {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
};
